import { FormikHelpers, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FixedAddressForm from '../../components/forms/FixedAddressForm/FixedAddressForm';
import { USA } from '../../constants/countries';
import {
  COUNTRY,
  FIXED_ADDRESS_VALIDATION_SCHEMA as validationSchema,
  INITIAL_FIXED_ADDRESS_FORM_STATE,
  STATE,
} from '../../constants/shipping';
import { setOneLinkFixedAddress } from '../../store/actions/oneLink';
import { selectOneLinkReceiverFixedAddress } from '../../store/selectors/bucket';
import { selectIsReceiverFixedAddressDrawerShown } from '../../store/selectors/shell';
import { IOneLinkReceiverFixedAddress } from '../../types/oneLink';
import { getRequiredFields } from '../../utils/form';
import { getOneLinkFixedAddressFields } from '../../utils/oneLink';
import { ReceiverFixedAddressSidebar } from '../index';

interface IProps {
  onClose: () => void | undefined;
  onEmptyInput?: () => void | undefined;
}

const ReceiverFixedAddressSidebarContainer: React.FC<IProps> = ({ onClose, onEmptyInput }) => {
  const dispatch = useDispatch();
  const isShown = useSelector(selectIsReceiverFixedAddressDrawerShown);
  const fixedAddress = useSelector(selectOneLinkReceiverFixedAddress);

  const initialValues = React.useMemo(() => {
    if (!fixedAddress || Object.keys(fixedAddress).length === 0) {
      return INITIAL_FIXED_ADDRESS_FORM_STATE;
    }

    return {
      ...INITIAL_FIXED_ADDRESS_FORM_STATE,
      ...getOneLinkFixedAddressFields(fixedAddress),
    };
  }, [fixedAddress]);

  const handleSubmit = React.useCallback(
    (values: IOneLinkReceiverFixedAddress, { resetForm }: FormikHelpers<IOneLinkReceiverFixedAddress>) => {
      if (!validationSchema.isValidSync(values)) {
        return;
      }

      dispatch(setOneLinkFixedAddress(values));
      resetForm();
      onClose();
    },
    [dispatch, validationSchema, onClose],
  );

  const form = useFormik<IOneLinkReceiverFixedAddress>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const requiredFields = React.useMemo(
    () => getRequiredFields(validationSchema, form.values[COUNTRY] === USA.two_digit_code ? [STATE] : []),
    [form.values[COUNTRY]],
  );

  const handleClose = React.useCallback(() => {
    form
      .validateForm(form.initialValues)
      .then((errors) => {
        if (!isEmpty(errors)) {
          onEmptyInput?.();
        }
      })
      .then(() => form.resetForm())
      .finally(onClose);
  }, [onClose, onEmptyInput, form.initialValues, form.validateForm, form.resetForm]);

  return (
    <ReceiverFixedAddressSidebar trigger={isShown} onClose={handleClose}>
      <FixedAddressForm
        onCancel={handleClose}
        onSubmit={form.handleSubmit}
        form={form}
        requiredFields={requiredFields}
      />
    </ReceiverFixedAddressSidebar>
  );
};

export default ReceiverFixedAddressSidebarContainer;
