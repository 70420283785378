import cn from 'classnames';
import * as React from 'react';
import TagsInput, { RenderTagProps } from 'react-tagsinput';

import { BUTTON_BUTTON } from '../../../../types/forms';
import { RedCrossButton } from '../../buttons';
import { InputLabel } from '../../labels';

import 'react-tagsinput/react-tagsinput.css';
import styles from './TagInput.module.scss';

export interface ITagInputProps {
  value: string[];
  label?: string | React.ReactElement[] | React.ReactElement;
  placeholder: string;
  className?: string;
  labelClassName?: string;
  onChange?: (value: string[] | null) => void;
  error?: string | { message: string; path: string };
  isReadOnly?: boolean;
}

const TagInput: React.FC<ITagInputProps> = ({
  value,
  label,
  className,
  labelClassName,
  onChange,
  placeholder,
  error,
  isReadOnly,
}) => {
  const [inputValue, setInputValue] = React.useState('');

  const isEmpty = React.useMemo(() => {
    return value.length === 0;
  }, [value]);

  const handleTagsChange = React.useCallback(
    (tagsArray: string[]) => {
      if (typeof onChange === 'function') {
        const valuesArray = tagsArray.filter((tag: string) => tag !== placeholder);
        onChange(valuesArray);
      }
    },
    [placeholder, onChange],
  );

  const handleClearTags = React.useCallback(() => {
    if (typeof onChange === 'function') {
      onChange([]);
    }
  }, [onChange]);

  const handleRemoveTag = React.useCallback(
    (tagIndex: number) => {
      if (onChange && typeof value[tagIndex] !== 'undefined') {
        const filtered = value?.filter((t, i) => {
          return i !== tagIndex;
        });

        onChange(filtered);
      }
    },
    [value, onChange],
  );

  const renderLayout = React.useCallback(
    (...args: any[]) => {
      const [tagElements, inputElement] = args;
      return (
        <React.Fragment>
          {!isEmpty && !isReadOnly && <RedCrossButton onClick={handleClearTags} className={styles.deleteButton} />}
          <div className={styles.tagsContainer}>
            {tagElements}
            {inputElement}
          </div>
        </React.Fragment>
      );
    },
    [isEmpty, handleClearTags, isReadOnly],
  );

  const renderTag = React.useCallback(
    ({ disabled, getTagDisplayValue, onRemove, tag, key }: RenderTagProps<string>) => {
      const hasError = (() => {
        if (error && Array.isArray(error)) {
          return !!error.find((err) => err?.index === key);
        }

        return false;
      })();

      return (
        <div
          className={cn('react-tagsinput-tag', styles.tagItem, {
            [styles.emptyTag]: isEmpty,
            [styles.error]: hasError,
          })}
          key={key}
        >
          {getTagDisplayValue(tag)}
          {!disabled && <button className={styles.removeBtn} onClick={() => onRemove(key)} type={BUTTON_BUTTON} />}
        </div>
      );
    },
    [error, isEmpty],
  );

  const renderError = React.useCallback(() => {
    if (typeof error === 'string') {
      return error;
    }

    // If the error is an array, it should have the `message` for each bad tag.
    // We are showing the last entered error to avoid the UI overload
    if (Array.isArray(error)) {
      return error[error.length - 1]?.message;
    }

    return null;
  }, [error]);

  const displayValue = React.useMemo(() => {
    if (value.length > 0) {
      return value;
    }

    return inputValue ? [] : [placeholder];
  }, [value, placeholder, inputValue]);

  return (
    <div className={styles.container}>
      {label && <InputLabel value={label} className={cn(styles.label, labelClassName)} />}
      <TagsInput
        disabled={isReadOnly}
        addKeys={[32, 188, 9, 13]}
        onlyUnique
        value={displayValue}
        onChange={handleTagsChange}
        onChangeInput={setInputValue}
        inputValue={inputValue}
        addOnBlur={!!inputValue.trim()}
        addOnPaste
        className={cn('react-tagsinput', styles.inputFieldContainer, error ? styles.errorBorder : null, className)}
        tagProps={{ onRemove: handleRemoveTag }}
        renderTag={renderTag}
        inputProps={{
          className: cn('react-tagsinput-input', styles.tagInput, { [styles.error]: !Array.isArray(error) && error }),
          placeholder: null,
        }}
        renderLayout={renderLayout}
      />
      {error && <div className={styles.error}>{renderError()}</div>}
    </div>
  );
};

export default TagInput;
