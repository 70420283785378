import { Field, FieldMetaProps, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import * as yup from 'yup';

import { IMPROVE_EMAIL_INSTRUCTIONS } from '../../../constants/ai';
import {
  EMAIL_MESSAGE,
  EMAIL_SUBJECT,
  NO_REQUIRED_DIGITAL_TAG_ERROR_MESSAGE,
  NO_REQUIRED_PHYSICAL_TAG_ERROR_MESSAGE,
  RECEIVER_EMAIL,
} from '../../../constants/templates';
import { IChatGPTSelectorValue } from '../../../types/shell';
import { IAddEmailFormValues } from '../../../types/templates';
import { getFirstErrorMessage } from '../../../utils/form';
import { HelpTooltip, ImproveAISelector } from '../../index';
import { ActionButton } from '../buttons';
import { Checkbox, Input, TinyEditor } from '../inputs';

import styles from './AddEmailForm.module.scss';

interface IProps {
  onSubmit: (values: IAddEmailFormValues) => void;
  initialValues: IAddEmailFormValues;
  onChange?: (name: keyof IAddEmailFormValues, value: string) => void;
  validation: yup.Schema<any>;
  isBulkEngagement?: boolean;
  toggleSkip: () => void;
  isSkipped?: boolean;
  isOneLink?: boolean;
  onImproveText: (v: IChatGPTSelectorValue) => void;
  isTextImproveSubmitting?: boolean;
}

const AddEmailForm = ({
  onSubmit,
  initialValues,
  validation,
  isBulkEngagement,
  toggleSkip,
  isSkipped,
  isOneLink,
  onChange,
  onImproveText,
  isTextImproveSubmitting,
}: IProps) => {
  const handleChange = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      const { name, value } = e.currentTarget;
      if (!name) {
        console.warn('No field name was specified');
        return;
      }
      if (typeof onChange === 'function') {
        onChange(name as keyof IAddEmailFormValues, value);
      }
    },
    [onChange],
  );

  const getFieldErrors = React.useCallback((fieldName: string, getFieldMeta: (name: string) => FieldMetaProps<any>) => {
    const { error } = getFieldMeta(fieldName);

    if (fieldName === EMAIL_MESSAGE) {
      return error === NO_REQUIRED_DIGITAL_TAG_ERROR_MESSAGE || error === NO_REQUIRED_PHYSICAL_TAG_ERROR_MESSAGE
        ? error
        : undefined;
    }

    return error;
  }, []);

  return (
    <Formik
      isInitialValid={() => validation.isValidSync(initialValues)}
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={isSkipped ? null : validation}
    >
      {({ isValid, setFieldValue, errors, getFieldMeta }) => (
        <Form className={styles.addEmailForm}>
          {!(isBulkEngagement || isOneLink) && (
            <Field
              helperText="Recipient’s Email"
              name={RECEIVER_EMAIL}
              className={styles.input}
              component={Input}
              disabled={isSkipped}
              placeholder="Email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(e.target.name, e.target.value.trim());
                if (onChange) {
                  onChange(e.target.name as keyof IAddEmailFormValues, e.target.value.trim());
                }
              }}
            />
          )}
          <Field
            helperText="Email subject"
            name={EMAIL_SUBJECT}
            className={styles.input}
            component={Input}
            onChange={handleChange}
            disabled={isSkipped}
            placeholder="Subject"
          />
          <Field name={EMAIL_MESSAGE}>
            {({ field }: any) => {
              return (
                <TinyEditor
                  field={field}
                  helperText={
                    <div className={styles.tinyEmailLabel}>
                      Email Text
                      {!isBulkEngagement && (
                        <HelpTooltip id="Email tooltip" className={styles.tinyEmailTooltip}>
                          Sender signature can be set in
                          <span className={styles.tinyLabelStrongFont}> Profile - Signature Setup</span> page
                        </HelpTooltip>
                      )}
                    </div>
                  }
                  error={getFieldErrors(EMAIL_MESSAGE, getFieldMeta)}
                  showErrors
                  disabled={isSkipped}
                  changeCallback={handleChange}
                  containerClassName={styles.editorContainer}
                  className={styles.tinyEmailEditor}
                />
              );
            }}
          </Field>
          <ImproveAISelector
            options={IMPROVE_EMAIL_INSTRUCTIONS}
            onImproveText={onImproveText}
            isFetching={isTextImproveSubmitting}
            disabled={isSkipped}
            label="Want to have the email improved by AI? Select what you want to do:"
          />
          <div className={styles.buttonInner}>
            <Checkbox
              containerClassName={styles.checkbox}
              text="Skip this step"
              checked={isSkipped}
              textAbout="This option allows you to skip email attaching to a send"
              onChange={toggleSkip}
            />
            <ActionButton
              disabled={!(isSkipped || isValid) || isTextImproveSubmitting}
              type="submit"
              className={styles.button}
              hint={getFirstErrorMessage(errors)}
              title="Continue"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddEmailForm;
