import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as QuestionIcon } from '../../assets/images/icon-check-question.svg';
import { SEND_ON_BEHALF_THE_EMAIL } from '../../constants/bucket';
import { addBucketValue } from '../../store/actions/bucket';
import { fetchUsersRequest } from '../../store/actions/users';
import { selectUserEmail } from '../../store/selectors/auth';
import { selectIsSOBEnabled, selectSOBEmail } from '../../store/selectors/bucket';
import { selectUsers } from '../../store/selectors/users';
import { ISelectorValue, PositionStylesEnum } from '../../types/shell';
import { Selector, Toggle } from '../forms';

import variables from '../../_variables.scss';
import UserOption from '../forms/inputs/Selector/Options/UserOption/UserOption';
import styles from './SOBSelector.module.scss';

interface IProps {
  className?: string;
}

const SOBSelector = ({ className }: IProps) => {
  const [isSOBToggleEnabled, setIsSOBToggleEnabled] = React.useState(false);

  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const isSOBEnabled = useSelector(selectIsSOBEnabled);
  const userEmail = useSelector(selectUserEmail);
  const SOBEmail = useSelector(selectSOBEmail);

  const usersOptions = React.useMemo(() => {
    if (!users || !users.length) {
      return null;
    }

    return users?.map((user) => {
      const { first_name, last_name, email } = user;

      return {
        value: email,
        label: `${first_name} ${last_name}`,
        user,
      };
    });
  }, [users]);

  const changeSOBSender = React.useCallback(
    (senderEmail: string | null) => {
      if (senderEmail === null) {
        setIsSOBToggleEnabled((prevStatus) => !prevStatus);
        dispatch(addBucketValue({ [SEND_ON_BEHALF_THE_EMAIL]: userEmail }));
      } else {
        dispatch(
          addBucketValue({
            [SEND_ON_BEHALF_THE_EMAIL]: senderEmail,
          }),
        );
      }
    },
    [dispatch, userEmail],
  );

  React.useEffect(() => {
    if (!isSOBToggleEnabled && isSOBEnabled) {
      setIsSOBToggleEnabled(true);
    }
  }, [isSOBEnabled, isSOBToggleEnabled]);

  React.useEffect(() => {
    if (isSOBToggleEnabled && (!users || !users.length)) {
      dispatch(fetchUsersRequest());
    }
  }, [isSOBToggleEnabled, users, dispatch]);

  return (
    <div className={cn(className, styles.container)}>
      <span className={styles.label}>Sender options</span>
      <div className={styles.main}>
        <div className={styles.toggleContainer}>
          <span className={styles.helperText}>
            Send on behalf
            <>
              <QuestionIcon data-for="main" data-tip />
              <ReactTooltip
                className={styles.tooltip}
                id="main"
                place={PositionStylesEnum.Right}
                type="light"
                effect="solid"
                border
                borderColor={variables.graySuit}
                aria-haspopup="true"
                offset={{ right: 10 }}
              >
                <p>If this option if ‘ON’ your gifts will be sent </p>
                <p>on behalf the person you choose. If it’s</p>
                <p>‘OFF’ it will be sent on your behalf.</p>
              </ReactTooltip>
            </>
          </span>
          <div className={styles.toggle}>
            <Toggle onChange={() => changeSOBSender(null)} checked={isSOBToggleEnabled} />
          </div>
        </div>
        {isSOBToggleEnabled && (
          <Selector
            isClearable={false}
            containerClassName={styles.selector}
            closeMenuOnSelect
            placeholder="Search sender"
            helperText="Choose sender"
            value={usersOptions?.find(({ value }) => value === SOBEmail)}
            onChange={(v) => {
              changeSOBSender((v as ISelectorValue)?.value);
            }}
            options={usersOptions || []}
            customOption={UserOption}
          />
        )}
      </div>
    </div>
  );
};

export default SOBSelector;
