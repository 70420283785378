import cn from 'classnames';
import { FieldAttributes } from 'formik';
import * as React from 'react';

import { ReactComponent as QuestionIcon } from '../../../../assets/images/icon-check-question.svg';

import styles from './Checkbox.module.scss';

interface IProps extends FieldAttributes<any> {
  containerClassName?: string;
  className?: string;
  id?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string | React.ReactNode;
  textAbout?: string;
  disabled?: boolean;
}

const Checkbox: React.FC<IProps> = ({
  containerClassName,
  className,
  id,
  checked,
  onChange,
  text,
  textAbout,
  field,
  disabled,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    if (field?.onChange) {
      field.onChange(e);
    }
  };

  const handleClick = React.useCallback((e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={cn(styles.checkboxWrapper, containerClassName)}>
      <label htmlFor={id} className={cn(styles.container, className)} onClick={handleClick}>
        <input
          id={id}
          name={field?.name || id}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className={styles.text}>{text}</span>
        <span className={styles.checkmark} />
      </label>
      {textAbout && (
        <div className={styles.helpInfo}>
          <QuestionIcon />
          <div className={styles.helpText}>{textAbout}</div>
        </div>
      )}
    </div>
  );
};

export default Checkbox;
