import cn from 'classnames';
import { FocusError } from 'focus-formik-error';
import { FormikProps } from 'formik';
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as IconUser } from '../../../assets/images/icon-user.svg';
import { CountrySelector } from '../../../components/index';
import {
  AWAITING_ADDRESS,
  IS_RECEIVER_ADDRESS_FIXED,
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_CRM_OPPORTUNITY_ID,
  RECEIVER_CRM_RECORD_ID,
  RECEIVER_CRM_RECORD_TYPE,
  RECEIVER_EMAIL,
  RECEIVER_FIRST_NAME,
  RECEIVER_FIXED_ADDRESS_HINT,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
  SHIPPING_FORM_CUSTOMIZABLE_ITEMS_HINT,
  SHIPPING_FORM_DS_HINT,
  SHIPPING_FORM_DS_PYG_HINT,
  SHIPPING_FORM_MSKU_HINT,
  SHIPPING_OPTION,
  SHIPPING_OPTIONS,
  SHIP_ORDER_STATUS,
} from '../../../constants/shipping';
import { selectIsPYGCampaignsDigital, selectIsPYGEnabled } from '../../../store/selectors/bucket';
import { IFormFieldConfig, ISelectorValue } from '../../../types/shell';
import { IShippingFormValues } from '../../../types/shipping';
import { HelpTooltip } from '../../tooltips';
import { HiddenInput, Input, RadioButtonGroup, Toggle } from '../inputs';

import styles from './ShippingForm.module.scss';

interface IProps {
  onChange: (...args: any[]) => void;
  disabled?: boolean;
  isCRMEnabled?: boolean;
  hasMsku?: boolean;
  hasCustomizableItems?: boolean;
  isShippingOptionEnabled?: boolean;
  form: FormikProps<Partial<IShippingFormValues>>;
  requiredFields: Record<string, boolean>;
  className?: string;
  config?: { [key in keyof Partial<IShippingFormValues>]: IFormFieldConfig };
  isDirectSend?: boolean;
}

const ShippingForm = ({
  onChange,
  disabled,
  isCRMEnabled,
  form,
  requiredFields,
  className,
  config,
  hasMsku,
  hasCustomizableItems,
  isShippingOptionEnabled,
  isDirectSend,
}: IProps) => {
  const getFieldErrors = React.useCallback(
    (fieldName: keyof IShippingFormValues) => {
      return form.errors[fieldName];
    },
    [form.errors],
  );

  const isPYGFlow = useSelector(selectIsPYGEnabled);
  const isDigitalPYG = useSelector(selectIsPYGCampaignsDigital);

  const delayedShippingHint = useMemo(() => {
    if (isPYGFlow) {
      return SHIPPING_FORM_DS_PYG_HINT;
    }

    if (hasMsku) {
      return SHIPPING_FORM_MSKU_HINT;
    }

    if (hasCustomizableItems) {
      return SHIPPING_FORM_CUSTOMIZABLE_ITEMS_HINT;
    }

    return SHIPPING_FORM_DS_HINT;
  }, [isPYGFlow, hasMsku, hasCustomizableItems]);

  const emailHint = React.useMemo(
    () => (
      <>
        Email
        <HelpTooltip className={styles.inputTooltip} id={RECEIVER_EMAIL}>
          If this field is empty we won’t be able to send package tracking email to receiver
        </HelpTooltip>
      </>
    ),
    [],
  );

  const handleSelectInputChange = React.useCallback(
    (name: keyof Partial<IShippingFormValues>, selected: ISelectorValue) => {
      const { value } = selected || {};
      onChange({ name, value });
    },
    [onChange],
  );

  // If Generate Claim Link is switched off or disabled, the Lock the Address toggle should be off
  React.useEffect(() => {
    if (!form.values[SHIP_ORDER_STATUS] && form.values[IS_RECEIVER_ADDRESS_FIXED]) {
      onChange({
        target: {
          name: IS_RECEIVER_ADDRESS_FIXED,
          checked: false,
        },
      });
    }
  }, [form.values[SHIP_ORDER_STATUS], form.values[IS_RECEIVER_ADDRESS_FIXED], onChange]);

  const isFixedAddressAvailable = React.useMemo(
    () => !isDigitalPYG && config?.[SHIP_ORDER_STATUS]?.disabled,
    [isDigitalPYG, config],
  );

  return (
    <form onSubmit={form.handleSubmit} className={cn(styles.shippingForm, className)}>
      <FocusError formik={form} />
      <div className={cn(styles.info)}>
        <Input
          helperText="First name"
          name={RECEIVER_FIRST_NAME}
          className={styles.input}
          placeholder="First name"
          disabled={disabled}
          required={requiredFields[RECEIVER_FIRST_NAME]}
          icon={<IconUser />}
          value={form.values[RECEIVER_FIRST_NAME]}
          onChange={onChange}
          error={getFieldErrors(RECEIVER_FIRST_NAME)}
        />
        <Input
          helperText="Last name"
          name={RECEIVER_LAST_NAME}
          className={styles.input}
          placeholder="Last name"
          disabled={disabled}
          required={requiredFields[RECEIVER_LAST_NAME]}
          icon={<IconUser />}
          onChange={onChange}
          value={form.values[RECEIVER_LAST_NAME]}
          error={getFieldErrors(RECEIVER_LAST_NAME)}
        />
        <Input
          helperText="Company"
          name={RECEIVER_COMPANY_NAME}
          className={styles.input}
          placeholder="Company"
          disabled={disabled}
          required={requiredFields[RECEIVER_COMPANY_NAME]}
          value={form.values[RECEIVER_COMPANY_NAME]}
          onChange={onChange}
          error={getFieldErrors(RECEIVER_COMPANY_NAME)}
        />
        <Input
          helperText="Phone"
          name={RECEIVER_PHONE}
          className={styles.input}
          placeholder="Phone"
          disabled={disabled}
          required={requiredFields[RECEIVER_PHONE]}
          value={form.values[RECEIVER_PHONE]}
          onChange={onChange}
          error={getFieldErrors(RECEIVER_PHONE)}
        />
        <Input
          helperText={emailHint}
          name={RECEIVER_EMAIL}
          className={cn(styles.input, styles.fullWidth)}
          placeholder="Email"
          disabled={disabled}
          required={requiredFields[RECEIVER_EMAIL]}
          value={form.values[RECEIVER_EMAIL]}
          onChange={onChange}
          error={getFieldErrors(RECEIVER_EMAIL)}
        />
        {!isDirectSend && (
          <div className={styles.togglesGrid}>
            <Toggle
              name={SHIP_ORDER_STATUS}
              disabled={disabled || config?.[SHIP_ORDER_STATUS]?.disabled}
              hint={delayedShippingHint}
              onChange={onChange}
              defaultValue={AWAITING_ADDRESS}
              value={form.values[SHIP_ORDER_STATUS]}
            >
              Delayed Shipping
            </Toggle>
            {isFixedAddressAvailable && (
              <Toggle
                name={IS_RECEIVER_ADDRESS_FIXED}
                disabled={disabled || !form.values[SHIP_ORDER_STATUS]}
                hint={RECEIVER_FIXED_ADDRESS_HINT}
                onChange={onChange}
                defaultValue={false}
                checked={!!form.values[IS_RECEIVER_ADDRESS_FIXED]}
              >
                Lock the address
              </Toggle>
            )}
          </div>
        )}
        <Input
          helperText="Address 1"
          name={RECEIVER_ADDRESS1}
          className={cn(styles.input, styles.fullWidth)}
          placeholder="Address 1"
          disabled={disabled}
          required={requiredFields[RECEIVER_ADDRESS1]}
          value={form.values[RECEIVER_ADDRESS1]}
          onChange={onChange}
          error={getFieldErrors(RECEIVER_ADDRESS1)}
        />
        <Input
          helperText="Address 2"
          name={RECEIVER_ADDRESS2}
          className={styles.input}
          placeholder="Address 2"
          disabled={disabled}
          required={requiredFields[RECEIVER_ADDRESS2]}
          value={form.values[RECEIVER_ADDRESS2]}
          onChange={onChange}
          error={getFieldErrors(RECEIVER_ADDRESS2)}
        />
        <Input
          helperText="City"
          name={RECEIVER_CITY}
          className={styles.input}
          placeholder="City"
          disabled={disabled}
          required={requiredFields[RECEIVER_CITY]}
          value={form.values[RECEIVER_CITY]}
          onChange={onChange}
          error={getFieldErrors(RECEIVER_CITY)}
        />
        <Input
          helperText="State"
          name={RECEIVER_STATE}
          className={styles.input}
          placeholder="State"
          disabled={disabled}
          required={requiredFields[RECEIVER_STATE]}
          value={form.values[RECEIVER_STATE]}
          onChange={onChange}
          error={getFieldErrors(RECEIVER_STATE)}
        />
        <Input
          helperText="ZIP Code"
          name={RECEIVER_ZIP}
          className={styles.input}
          placeholder="ZIP Code"
          disabled={disabled}
          required={requiredFields[RECEIVER_ZIP]}
          value={form.values[RECEIVER_ZIP]}
          onChange={onChange}
          error={getFieldErrors(RECEIVER_ZIP)}
        />
        <CountrySelector
          containerClassName={cn(styles.input, styles.inputWidth)}
          className={cn(styles.countrySelect, { [styles.errorBorder]: form.errors[RECEIVER_COUNTRY] })}
          label="Country"
          name={RECEIVER_COUNTRY}
          required={requiredFields[RECEIVER_COUNTRY]}
          value={form.values[RECEIVER_COUNTRY]}
          onChange={handleSelectInputChange}
          error={getFieldErrors(RECEIVER_COUNTRY)}
          disabled={disabled}
        />
      </div>
      {isShippingOptionEnabled ? (
        <div className={cn(styles.optionsList)}>
          <span className={styles.label}>Delivery Options</span>
          <RadioButtonGroup
            options={SHIPPING_OPTIONS}
            value={form.values[SHIPPING_OPTION]}
            disabled={disabled}
            onChange={onChange}
            name={SHIPPING_OPTION}
          />
        </div>
      ) : (
        <HiddenInput name={SHIPPING_OPTION} value={form.values[SHIPPING_OPTION]} />
      )}
      {isCRMEnabled && !isDirectSend && (
        <React.Fragment>
          <HiddenInput name={RECEIVER_CRM_RECORD_ID} value={form.values[RECEIVER_CRM_RECORD_ID]} />
          <HiddenInput name={RECEIVER_CRM_RECORD_TYPE} value={form.values[RECEIVER_CRM_RECORD_TYPE]} />
          <HiddenInput name={RECEIVER_CRM_OPPORTUNITY_ID} value={form.values[RECEIVER_CRM_OPPORTUNITY_ID]} />
        </React.Fragment>
      )}
    </form>
  );
};

export default ShippingForm;
