import { IChangeBucketItemPayload } from '../../../types/bucket';
import { IReportEngagementItem } from '../../../types/reports';

enum actionTypes {
  Init = 'init',
  Change = 'change',
  Remove = 'remove',
  Replace = 'replace',
}

export type IPayload = IReportEngagementItem[] | IReportEngagementItem | null | IChangeBucketItemPayload | string;

export interface IAction {
  type: actionTypes;
  payload?: IPayload;
}

const reducer =
  (items?: IReportEngagementItem[] | null) =>
  (state: IReportEngagementItem[], { type, payload }: IAction) => {
    switch (type) {
      case actionTypes.Init:
        return payload && Array.isArray(payload) && payload.length ? payload.concat() : items || [];
      case actionTypes.Change:
        return state.map((item) => {
          const { id, name, value } = payload as IChangeBucketItemPayload;
          if (item.item_id === id) {
            return { ...item, [name]: value };
          }
          return item;
        });
      case actionTypes.Remove:
        return state.filter((item) => item.item_id !== (payload as string));
      case actionTypes.Replace:
        return payload && Array.isArray(payload) && payload.length ? payload.concat() : [];
      default:
        return state;
    }
  };

const actions = {
  init: (payload?: IReportEngagementItem[]) => ({ type: actionTypes.Init, payload }),
  change: (payload: IChangeBucketItemPayload) => ({ type: actionTypes.Change, payload }),
  replace: (payload: IReportEngagementItem[]) => ({ type: actionTypes.Replace, payload }),
  remove: (payload: string) => ({ type: actionTypes.Remove, payload }),
};

export { actions, reducer };
