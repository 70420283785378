import { FormikProps } from 'formik';
import { ElementType } from 'react';
import { ICampaignFormItem } from './campaigns';

export const EMAIL_INPUT = 'email';
export const PASSWORD_INPUT = 'password';

export const SUBMIT_BUTTON = 'submit';
export const BUTTON_BUTTON = 'button';

export const CHECKBOX = 'checkbox';

export enum MaintenanceFormStateEnum {
  Adding,
  Editing,
  Reading,
}

export enum EditableTableModes {
  Add,
  Edit,
}

export enum FormStatusEnum {
  Start,
  Success,
  Error,
}

export interface IMaintenanceFormOutputData {
  mode: MaintenanceFormStateEnum;
  className?: string;
  root: string;
}

export interface IDropzoneResult {
  files: File[];
  resolve: (...args: any[]) => void;
  reject: (...args: any[]) => void;
}

export interface ICampaignFormProps<T> extends IMaintenanceFormOutputData {
  onFulfillmentCenterChange: (id: string) => void;
  fulfillmentCenterId: string | null;
  readOnly: boolean;
  onChange?: (name: keyof T, value: ICampaignFormItem[] | string | number | boolean | undefined) => void;
  form: FormikProps<Partial<T>>;
}

export interface IRadioButtonOption {
  label: string;
  value: string;
  icon?: ElementType;
}

export interface ITextCustomizationForm {
  id: string;
  tabLabel: string;
  title_name: string;
  body_name: string;
  shouldHideTitle?: boolean;
  shouldHideBody?: boolean;
  titleLabel: string;
  bodyLabel: string;
  titleHintImage: string;
  bodyHintImage: string;
}
