import {
  CRM_TYPE,
  IS_RECEIVER_ADDRESS_FIXED,
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_CRM_OPPORTUNITY_ID,
  RECEIVER_CRM_RECORD_ID,
  RECEIVER_CRM_RECORD_TYPE,
  RECEIVER_EMAIL,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
  SHIPPING_OPTION,
  SHIP_ORDER_STATUS,
} from '../constants/shipping';

export enum ShippingOptionsEnum {
  Ground = 'ground',
  TwoDays = '2-day',
  ThreeDays = '3-day',
  OverNight = 'overnight',
}

export interface IShippingFormValues {
  [RECEIVER_FIRST_NAME]: string;
  [RECEIVER_LAST_NAME]: string;
  [RECEIVER_COMPANY_NAME]?: string;
  [RECEIVER_CRM_RECORD_ID]?: string;
  [RECEIVER_CRM_RECORD_TYPE]?: string;
  [RECEIVER_CRM_OPPORTUNITY_ID]?: string;
  [RECEIVER_PHONE]?: string;
  [RECEIVER_ADDRESS1]: string;
  [RECEIVER_ADDRESS2]: string;
  [RECEIVER_STATE]?: string;
  [RECEIVER_ZIP]: string;
  [RECEIVER_COUNTRY]?: string;
  [RECEIVER_CITY]: string;
  [RECEIVER_EMAIL]: string;
  [IS_RECEIVER_ADDRESS_FIXED]?: boolean;
  [SHIP_ORDER_STATUS]: string;
  [SHIPPING_OPTION]: ShippingOptionsEnum;
  [CRM_TYPE]?: string;
}

export interface ISendDetailsFormValues
  extends Omit<
    IShippingFormValues,
    | 'crm_type'
    | 'receiver_email'
    | 'receiver_crm_record_type'
    | 'receiver_crm_record_id'
    | 'receiver_crm_opportunity_id'
  > {
  note_message?: string;
}
