import differenceInDays from 'date-fns/differenceInDays';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import identity from 'lodash/identity';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';

import { CREATED_AT } from '../constants/reports';
import {
  DEFAULT_RECT_LOGO_PATH,
  DEFAULT_SQUARE_LOGO_PATH,
  THEME_DEFAULT_ACCENT_COLOR,
  THEME_DEFAULT_SIDEBAR_COLOR,
  THEME_DEFAULT_SIDEBAR_TEXT_COLOR,
} from '../constants/shell';
import { ICampaign, ICampaignItem } from '../types/campaigns';
import { IOneLinkReceiverFixedAddress } from '../types/oneLink';
import { ITheme } from '../types/shell';
import { getCountryNameByTwoDigits } from './country';

const HEX_REGEX = new RegExp('^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$', 'gm');

export const convertArrayToObject = <T extends { [s: string]: any }, K extends keyof T>(
  array: T[],
  key: K,
  result: { [k: string]: T } = {},
): { [k: string]: T } =>
  array.reduce<{ [k: string]: T }>(
    (acc: { [k: string]: T }, current: T) => ({
      ...acc,
      [current[key.toString()]]: current,
    }),
    result,
  );

export const roundTo = (num: number, places: number): number => {
  const factor = 10 ** places;
  return Math.round(num * factor) / factor;
};

export const isObjectsEqual = <T>(value: T, other: T) => {
  if (!isObject(value) || !isObject(other)) {
    return false;
  }
  // The combination of pickBy and identity removes null and undefined properties
  return isEqual(pickBy(value, identity), pickBy(other, identity));
};

export const areCampaignsEqual = (
  first: Partial<ICampaign> | null | undefined,
  second: Partial<ICampaign> | null | undefined,
) => {
  if (!first || !second) {
    return false;
  }
  const { items: firstItems, ...firstCampaign } = first;
  const { items: secondItems, ...secondCampaign } = second;

  const mapper = (itemsArray: ICampaignItem[] | undefined) => {
    return itemsArray?.map(({ item_id, quantity, is_hidden_for_recipient }) => ({
      item_id,
      quantity,
      // sometimes in originalBox items there will be no is_hidden_for_recipient field, but boxDetails will always have it
      // so this explicit cast to boolean is intentional
      is_hidden_for_recipient: Boolean(is_hidden_for_recipient),
    }));
  };

  const areCampaignFieldsEqual = isObjectsEqual(firstCampaign, secondCampaign);
  const areItemsEqual = isEqual(mapper(firstItems), mapper(secondItems));

  return areCampaignFieldsEqual && areItemsEqual;
};

export const getDynamicRoot = () => document.getElementById('dynamic-root');

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex || '');
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
};

export const isColor = (color: string) => {
  const el = new Option().style;
  if (HEX_REGEX.test(color)) {
    return true;
  }

  el.color = color;
  return !!el.color;
};

export const getIsLowInventoryItemsNotificationSnoozed = (snoozedAt: string) => {
  if (snoozedAt) {
    const snoozedDate = new Date(snoozedAt);
    const currentDate = new Date();
    return differenceInDays(currentDate, snoozedDate) < 7;
  }

  return false;
};

export const getIsLowInventoryItemsNotificationShownToday = (shownAt: string) => {
  if (shownAt) {
    const shownDate = new Date(shownAt);
    const currentDate = new Date();

    return isSameDay(currentDate, shownDate);
  }

  return false;
};

/**
 *
 * @param {object} config - object where field names are css var name and values are values
 * @return void
 * @example
 * setCSSGlobalVariables({
 *  [`--accent-color`]: accentColor,
 *  [`--sidebar-color`]: sidebarColor,
 *  [`--sidebar-text-color`]: sidebarTextColor,
 *  });
 */
export const setCSSGlobalVariables = (config: { [k: string]: string | null }) => {
  for (const fieldName in config) {
    if (config.hasOwnProperty(fieldName)) {
      document.getElementById('root')?.style.setProperty(fieldName, config[fieldName]);
    }
  }
};

export const setThemeToCSSVariables = (theme: ITheme | undefined) => {
  if (!theme) {
    return;
  }
  const {
    portal_accent_color = THEME_DEFAULT_ACCENT_COLOR,
    portal_sidebar_color = THEME_DEFAULT_SIDEBAR_COLOR,
    portal_sidebar_text_color = THEME_DEFAULT_SIDEBAR_TEXT_COLOR,
    org_rect_logo,
    org_square_logo,
  } = theme;

  setCSSGlobalVariables({
    [`--accent-color`]: hexToRgb(portal_accent_color),
    [`--sidebar-color`]: hexToRgb(portal_sidebar_color),
    [`--sidebar-text-color`]: hexToRgb(portal_sidebar_text_color),
    [`--org-rect-logo`]: org_rect_logo ? `url('${org_rect_logo}')` : `url('${DEFAULT_RECT_LOGO_PATH}')`,
    [`--org-square-logo`]:
      org_square_logo || org_rect_logo
        ? `url('${org_square_logo || org_rect_logo}')`
        : `url('${DEFAULT_SQUARE_LOGO_PATH}')`,
  });
};

export const sortByCreatedAt = <T extends { [CREATED_AT]: string }>(current: T, next: T) =>
  isAfter(new Date(next[CREATED_AT]), new Date(current[CREATED_AT])) ? 1 : -1;

/**
 * A function for correct positioning of the tooltip on the edge of the screen
 * @link https://wwayne.github.io/react-tooltip/#:~:text=Type%20something...%27%20/%3E%20%0A%3C/ReactTooltip%3E-,Override%20position,-Try%20to%20resize
 * @param {number} left
 * @param {number} top
 * @param {Event} currentEvent
 * @param {EventTarget} currentTarget
 * @param {HTMLDivElement | HTMLSpanElement | null} node
 */
export const overrideTooltipPosition = (
  { left, top }: { left: number; top: number },
  currentEvent: Event,
  currentTarget: EventTarget,
  node: HTMLDivElement | HTMLSpanElement | null,
) => {
  const d = document.documentElement;
  left = Math.min(d.clientWidth - (node?.clientWidth || 0), left);
  top = Math.min(d.clientHeight - (node?.clientHeight || 0), top);
  left = Math.max(0, left);
  top = Math.max(0, top);
  return { top, left };
};

/**
 * A comparison function to sort objects alphabetically by their `name` property in ascending order.
 *
 * @param {Object} a - The first object for comparison.
 * @param {string} a.name - The name property of the first object.
 * @param {Object} b - The second object for comparison.
 * @param {string} b.name - The name property of the second object.
 * @param {string|string[]} [locales='en'] - Optional. A string with a BCP 47 language tag, or an array of such strings, that defines the locale to be used in the comparison.
 * @param {Intl.CollatorOptions} [options] - Optional. An object with configuration options for the comparison.
 *
 * @returns {number} - Returns a negative number if `a.name` comes before `b.name`; returns a positive number if `a.name` comes after `b.name`; returns 0 if they are considered equal.
 * @description Locales defaults to `en`
 * @description options defaults to { numeric: false, ignorePunctuation: true }
 */
export const sortByNameAlphabeticallyAscending = (
  a: { name: string },
  b: { name: string },
  locales?: string | string[],
  options?: Intl.CollatorOptions,
) =>
  a.name.localeCompare(b.name, locales ?? 'en', {
    numeric: false,
    ignorePunctuation: true,
    sensitivity: 'base',
    ...options,
  });

export const moveArrayElements = <T>(array: T[], from: number, to: number): T[] => {
  const resultArray = array.concat();
  resultArray.splice(to, 0, resultArray.splice(from, 1)[0]);
  return resultArray;
};

export const getAddressFromJSON = (address: IOneLinkReceiverFixedAddress) => {
  if (!address) {
    return '';
  }

  const country = getCountryNameByTwoDigits(address.country);

  const mappedAddress = {
    ...address,
    country: country?.name,
  };

  return Object.values(mappedAddress).filter(Boolean).join(', ');
};

export const convertValueToNumber = (value: number | string | null): number => {
  switch (typeof value) {
    case 'string':
      const parsedValue = parseFloat(value.replace(/,/g, ''));
      return isNaN(parsedValue) ? 0 : parsedValue;
    case 'number':
      return value;
    default:
      return 0;
  }
};
