import { SupportedFlowsEnum } from '../../constants/routing';
import { IBucketState, IBulkEngagementSubmitProps, IChangeBucketItemPayload, IFlowStepProps } from '../../types/bucket';
import { ICampaign, ICampaignItem, ICustomSend } from '../../types/campaigns';
import { IOnDemandItem } from '../../types/inventories';
import { IOneLinkRecipient, IOneLinkRecipients } from '../../types/oneLink';
import { IShippingFormValues } from '../../types/shipping';
import { IAddEmailFormValues, IAddNoteFormValues } from '../../types/templates';

export const START_ENGAGEMENT_FLOW = 'START_ENGAGEMENT_FLOW';
export const ADD_TO_BUCKET = 'ADD_TO_BUCKET';
export const REMOVE_FROM_BUCKET = 'REMOVE_FROM_BUCKET';
export const ADD_SHIPPING_INFORMATION = 'ADD_SHIPPING_INFORMATION';
export const ADD_SHIPPING_VALUE = 'ADD_SHIPPING_VALUE';
export const ADD_NOTE = 'ADD_NOTE';
export const ADD_EMAIL = 'ADD_EMAIL';
export const FLUSH_BUCKET = 'FLUSH_BUCKET';
export const PRE_SELECTED_BOX_CONFIRMED = 'PRE_SELECTED_BOX_CONFIRMED';
export const PICK_YOUR_GIFT_BOXES_CONFIRMED = 'PICK_YOUR_GIFT_BOXES_CONFIRMED';
export const RESET_BUCKET_ERRORS = 'RESET_BUCKET_ERRORS';
export const ADD_BULK_DATA = 'ADD_BULK_DATA';
export const CLEAR_BULK_DATA = 'CLEAR_BULK_DATA';
export const ADD_BUCKET_VALUE = 'ADD_BUCKET_VALUE';
export const CHANGE_BUCKET_ITEM_VALUE = 'CHANGE_BUCKET_ITEM_VALUE';

export const BULK_ENGAGEMENT_REQUEST = 'BULK_ENGAGEMENT_REQUEST';
export const BULK_ENGAGEMENT_SUCCESS = 'BULK_ENGAGEMENT_SUCCESS';
export const BULK_ENGAGEMENT_FAILURE = 'BULK_ENGAGEMENT_FAILURE';
export const BULK_ENGAGEMENT_API_FAILURE = 'BULK_ENGAGEMENT_API_FAILURE';

export const TOGGLE_PICK_YOUR_GIFT = 'TOGGLE_PICK_YOUR_GIFT';
export const SET_PICK_YOUR_GIFT = 'SET_PICK_YOUR_GIFT';
export const UPLOAD_BULK_CSV = 'UPLOAD_BULK_CSV';
export const RESET_CUSTOM_SEND = 'RESET_CUSTOM_SEND';

export const UPLOAD_2FA_RECIPIENTS = 'UPLOAD_2FA_RECIPIENTS';
export const DELETE_ALL_ALLOWED_RECIPIENTS = 'DELETE_ALL_ALLOWED_RECIPIENTS';
export const ADD_ALLOWED_RECIPIENT = 'ADD_ALLOWED_RECIPIENT';
export const EDIT_ALLOWED_RECIPIENT = 'EDIT_ALLOWED_RECIPIENT';
export const DELETE_ALLOWED_RECIPIENT = 'DELETE_ALLOWED_RECIPIENT';

export const TOGGLE_SKIP_NOTE = 'TOGGLE_SKIP_NOTE';

export const clearBulkData = () => ({
  type: CLEAR_BULK_DATA,
});
export const addBulkData = (payload?: any) => ({
  type: ADD_BULK_DATA,
  payload,
});
export const addToBucket = (payload: ICampaignItem | IOnDemandItem) => ({
  type: ADD_TO_BUCKET,
  payload,
});

export const removeFromBucket = (payload: string) => ({
  type: REMOVE_FROM_BUCKET,
  payload,
});

export const changeBucketItemValue = (payload: IChangeBucketItemPayload) => ({
  type: CHANGE_BUCKET_ITEM_VALUE,
  payload,
});

export const addShippingInformation = (payload?: Partial<IShippingFormValues>, metadata?: IFlowStepProps) => ({
  type: ADD_SHIPPING_INFORMATION,
  payload,
  metadata,
});

export const addShippingValue = (payload: { [name: string]: string | boolean }) => ({
  type: ADD_SHIPPING_VALUE,
  payload,
});

export const addNote = (payload: IAddNoteFormValues) => ({
  type: ADD_NOTE,
  payload,
});

export const toggleSkipNote = (payload: { isNoteSkipped: boolean }) => ({
  type: TOGGLE_SKIP_NOTE,
  payload,
});

export const addEmail = (payload: Partial<IAddEmailFormValues>) => ({
  type: ADD_EMAIL,
  payload,
});

export const flushBucket = () => ({
  type: FLUSH_BUCKET,
});

export const preSelectedBoxConfirmed = (payload: ICampaign | ICustomSend, metadata?: IFlowStepProps) => ({
  type: PRE_SELECTED_BOX_CONFIRMED,
  payload,
  metadata,
});

export const pickYourGiftBoxesConfirmed = (payload: ICampaign[], metadata?: IFlowStepProps) => ({
  type: PICK_YOUR_GIFT_BOXES_CONFIRMED,
  payload,
  metadata,
});

export const resetBucketErrors = () => ({ type: RESET_BUCKET_ERRORS });

export const bulkEngagementRequest = (payload: IBulkEngagementSubmitProps) => ({
  type: BULK_ENGAGEMENT_REQUEST,
  payload,
});

export const bulkEngagementSuccess = (payload?: any) => ({
  type: BULK_ENGAGEMENT_SUCCESS,
  payload,
});

export const bulkEngagementFailure = (payload?: any) => ({
  type: BULK_ENGAGEMENT_FAILURE,
  payload,
});

export const bulkEngagementApiFailure = (payload?: any) => ({
  type: BULK_ENGAGEMENT_API_FAILURE,
  payload,
});

export const startEngagementFlow = (payload?: SupportedFlowsEnum) => ({
  type: START_ENGAGEMENT_FLOW,
  payload,
});

export const addBucketValue = (payload: Partial<IBucketState>) => ({
  type: ADD_BUCKET_VALUE,
  payload,
});

export const togglePYG = (payload: boolean) => ({
  type: TOGGLE_PICK_YOUR_GIFT,
  payload,
});

export const setPYG = (payload: ICampaign) => ({
  type: SET_PICK_YOUR_GIFT,
  payload,
});

export const upload2FARecipients = (payload: IOneLinkRecipients) => ({
  type: UPLOAD_2FA_RECIPIENTS,
  payload,
});

export const uploadBulkCsv = () => ({
  type: UPLOAD_BULK_CSV,
});

export const resetCustomSend = () => ({
  type: RESET_CUSTOM_SEND,
});

export const deleteAllAllowedRecipients = () => ({
  type: DELETE_ALL_ALLOWED_RECIPIENTS,
});

export const addAllowedRecipient = (payload: IOneLinkRecipient) => ({
  type: ADD_ALLOWED_RECIPIENT,
  payload,
});

export const editAllowedRecipient = (payload: IOneLinkRecipient) => ({
  type: EDIT_ALLOWED_RECIPIENT,
  payload,
});

export const deleteAllowedRecipient = (id: string) => ({
  type: DELETE_ALLOWED_RECIPIENT,
  payload: id,
});
