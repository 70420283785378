import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReceiverFixedAddressSidebarContainer } from '../../containers';
import { toggleUiElement } from '../../store/actions/shell';
import { selectOneLinkReceiverFixedAddress } from '../../store/selectors/bucket';
import { selectIsReceiverFixedAddressDrawerShown } from '../../store/selectors/shell';
import { IToggleRef } from '../../types/shell';
import { getAddressFromJSON } from '../../utils/helpers';
import { ActionButton } from '../forms';

import styles from './ReceiverFixedAddressToggle.module.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  onEmptyInput?: () => void;
}

const ReceiverFixedAddressToggle = React.forwardRef(
  ({ className, disabled, onEmptyInput }: IProps, ref: React.ForwardedRef<IToggleRef>) => {
    const dispatch = useDispatch();
    const isSidebarOpened = useSelector(selectIsReceiverFixedAddressDrawerShown);
    const fixedAddress = useSelector(selectOneLinkReceiverFixedAddress);

    const handleToggleSidebar = React.useCallback(
      (flag?: boolean) => {
        dispatch(toggleUiElement({ receiverFixedAddressSidenavIsShown: flag }));
      },
      [dispatch, fixedAddress],
    );

    const handleClick = React.useCallback(() => {
      handleToggleSidebar(!isSidebarOpened);
    }, [handleToggleSidebar, isSidebarOpened]);

    React.useImperativeHandle(ref, () => ({
      open: () => handleToggleSidebar(true),
      close: () => handleToggleSidebar(false),
      toggle: () => handleToggleSidebar(!isSidebarOpened),
      isOpened: isSidebarOpened,
    }));

    const fixedAddressLabel = React.useMemo(() => {
      return fixedAddress ? getAddressFromJSON(fixedAddress) : '';
    }, [fixedAddress]);

    return (
      <>
        <ActionButton
          inline
          title={fixedAddressLabel ? fixedAddressLabel : 'Enable to deliver to a specific address'}
          disabled={disabled}
          onClick={handleClick}
          className={cn(styles.container, className)}
        />
        <ReceiverFixedAddressSidebarContainer onClose={() => handleToggleSidebar(false)} onEmptyInput={onEmptyInput} />
      </>
    );
  },
);

export default ReceiverFixedAddressToggle;
