import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import { IMPROVE_HANDWRITTEN_NOTE_INSTRUCTIONS } from '../../../constants/ai';
import { MAX_BULK_NOTE_LENGTH, MAX_NOTE_LENGTH, MAX_ONE_LINK_NOTE_LENGTH, MESSAGE } from '../../../constants/templates';
import { IChatGPTSelectorValue } from '../../../types/shell';
import { IAddNoteFormValues } from '../../../types/templates';
import { ImproveAISelector } from '../../index';
import { ActionButton } from '../buttons';
import { Checkbox, Textarea } from '../inputs';

import styles from './AddNoteForm.module.scss';

interface IProps {
  onSubmit: (values: any) => void;
  initialValues: IAddNoteFormValues;
  validation: yup.Schema<any>;
  toggleSkip: () => void;
  isSkipped?: boolean;
  isBulkEng?: boolean;
  onImproveText: (v: IChatGPTSelectorValue, message: string) => void;
  isTextImproveSubmitting?: boolean;
  isOneLinkEng?: boolean;
}

const AddNoteForm = ({
  onSubmit,
  initialValues,
  validation,
  toggleSkip,
  isSkipped,
  isBulkEng,
  isOneLinkEng,
  onImproveText,
  isTextImproveSubmitting,
}: IProps) => {
  const maxChars = React.useMemo(() => {
    switch (true) {
      case isBulkEng:
        return MAX_BULK_NOTE_LENGTH;
      case isOneLinkEng:
        return MAX_ONE_LINK_NOTE_LENGTH;

      default:
        return MAX_NOTE_LENGTH;
    }
  }, [isBulkEng, isOneLinkEng]);

  return (
    <Formik
      isInitialValid={() => validation.isValidSync(initialValues)}
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={isSkipped ? null : validation}
    >
      {({ isValid }) => (
        <Form className={styles.addNoteForm}>
          <div className={styles.textareaInner}>
            <Field name={MESSAGE}>
              {({ field, form, meta }: any) => {
                return (
                  <Textarea
                    field={field}
                    form={form}
                    meta={meta}
                    onMount={() => form.setFieldTouched(MESSAGE)} // TODO: Remove this workaround after formik update.
                    className={styles.textarea}
                    inputClassName={styles.input}
                    disabled={isSkipped}
                    helperText="Message text"
                    placeholder="Message"
                    showCounter={!isSkipped}
                    maxCharsCount={maxChars}
                  />
                );
              }}
            </Field>
          </div>
          <Field>
            {({ form }: any) => {
              return (
                <ImproveAISelector
                  options={IMPROVE_HANDWRITTEN_NOTE_INSTRUCTIONS}
                  onImproveText={(instruction) => onImproveText(instruction, form.values[MESSAGE])}
                  isFetching={isTextImproveSubmitting}
                  disabled={isSkipped}
                  label="Want to have the message improved by AI? Select what you want to do:"
                />
              );
            }}
          </Field>
          <div className={styles.buttonInner}>
            <Checkbox
              containerClassName={styles.checkbox}
              text="Skip this step"
              checked={isSkipped}
              textAbout="This option allows you to skip attaching a custom message to a send"
              onChange={toggleSkip}
            />
            <ActionButton
              disabled={!isValid || isTextImproveSubmitting}
              type="submit"
              className={styles.button}
              title="Continue"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddNoteForm;
