import cn from 'classnames';
import { FieldAttributes } from 'formik';
import * as React from 'react';

import { RedCrossButton } from '../../buttons';
import { InputLabel } from '../../labels';

import styles from './Input.module.scss';

// TODO: fix extends. It works incorrect

export interface IInputProps extends FieldAttributes<any> {
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onClear?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  placeholder?: string;
  type?: 'number' | 'email' | 'password' | 'text';
  value?: string | number;
  error?: string | string[] | null;
  helperText?: string | React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  innerClassName?: string;
  errorClassName?: string;
  icon?: React.ReactNode;
  left?: React.ReactNode;
  hint?: string;
  onMount?: () => void;
  readOnly?: boolean;
  name?: string;
  showErrors?: boolean;
  inline?: boolean;
  autoFocus?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  shouldTrimValue?: boolean;
}

const Input: React.FC<IInputProps> = ({
  id,
  name,
  onChange,
  onBlur,
  onFocus,
  onClick,
  onClear,
  placeholder,
  type = 'text',
  value,
  error,
  helperText,
  required,
  disabled,
  className,
  innerClassName,
  inputClassName,
  errorClassName,
  icon,
  field,
  onMount,
  readOnly,
  showErrors = true,
  inline,
  autoFocus,
  onKeyDown,
  shouldTrimValue,
  hint,
  left,
  ...rest
}) => {
  React.useEffect(() => {
    if (typeof onMount === 'function') {
      onMount();
    }
  }, []);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name: fieldName, value: fieldValue } = e.currentTarget;

      const event = shouldTrimValue
        ? {
            ...e,
            currentTarget: { ...e.currentTarget, name: fieldName, value: fieldValue ? fieldValue.trim() : '' },
          }
        : e;

      if (typeof field?.onChange === 'function') {
        field.onChange(event);
      }
      if (onChange) {
        onChange(event);
      }
    },
    [onChange, field, shouldTrimValue],
  );

  const handleBlur = (e: any) => {
    if (typeof field?.onBlur === 'function') {
      field.onBlur(e);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const shouldShowValidationError = React.useMemo(
    () => showErrors && !readOnly && error,
    [showErrors, readOnly, error],
  );

  const renderValidationError = React.useMemo(() => {
    if (!shouldShowValidationError) {
      return null;
    }
    return Array.isArray(error) ? (
      error.map((e) => (
        <span key={e} className={cn(styles.error, errorClassName)}>
          {e}
        </span>
      ))
    ) : (
      <span className={cn(styles.error, errorClassName)}>{error}</span>
    );
  }, [error, errorClassName, shouldShowValidationError]);

  const inputValue = React.useMemo(() => {
    const fieldValue = shouldTrimValue && field?.value ? field?.value.trim() : field?.value;
    const setValue = shouldTrimValue && typeof value === 'string' ? value?.trim() : value;
    return value === undefined || value === null ? fieldValue : setValue;
  }, [value, field?.value, shouldTrimValue]);

  return (
    <div className={cn(styles.inputContainer, className, { [styles.inline]: inline })}>
      <InputLabel value={helperText} required={required} />
      <div
        className={cn(styles.inputInner, innerClassName, { [styles.errorBorder]: !!shouldShowValidationError })}
        tabIndex={-10}
      >
        {onClear ? <RedCrossButton className={styles.clearBtn} onClick={onClear} /> : null}
        {left}
        <input
          {...rest}
          name={name || field?.name}
          id={id}
          type={type}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={onFocus}
          onClick={onClick}
          value={inputValue}
          placeholder={placeholder}
          className={cn(styles.input, inputClassName, {
            [styles.inline]: inline,
          })}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          onKeyDown={onKeyDown}
        />
        {icon}
      </div>
      {renderValidationError}
    </div>
  );
};

export default Input;
