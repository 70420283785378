import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers, Reducer } from 'redux';

import { IAuthState } from '../../types/auth';
import { IAutomationsState } from '../../types/automations';
import { IBucketState } from '../../types/bucket';
import { ICampaignsState } from '../../types/campaigns';
import { IDashboardState } from '../../types/dashboard';
import { IFulfillmentCentersState } from '../../types/fulfillmentCenters';
import { IIntegrationsState } from '../../types/integrations';
import { IInventoriesState } from '../../types/inventories';
import { IModalState } from '../../types/modals';
import { IOneLinksState } from '../../types/oneLink';
import { IOrganizationsState } from '../../types/organizations';
import { IReasonsState } from '../../types/reasons';
import { IReportsState } from '../../types/reports';
import { IShellState } from '../../types/shell';
import { ITemplatesState } from '../../types/templates';
import { IToolsState } from '../../types/tools';
import { IUsersState } from '../../types/users';

import auth from './auth';
import automations from './automations';
import bucket from './bucket';
import campaigns from './campaigns';
import dashboard from './dashboard';
import fulfillmentCenters from './fulfillmentCenters';
import integrations from './integrations';
import inventories from './inventories';
import modals from './modals';
import oneLink from './oneLink';
import organizations from './organizations';
import reasons from './reasons';
import reports from './reports';
import shell from './shell';
import templates from './templates';
import tools from './tools';
import users from './users';

export interface IAppState {
  auth: IAuthState;
  bucket: IBucketState;
  dashboard: IDashboardState;
  modals: IModalState;
  integrations: IIntegrationsState;
  campaigns: ICampaignsState;
  inventories: IInventoriesState;
  reports: IReportsState;
  oneLink: IOneLinksState;
  templates: ITemplatesState;
  users: IUsersState;
  automations: IAutomationsState;
  reasons: IReasonsState;
  organizations: IOrganizationsState;
  shell: IShellState;
  tools: IToolsState;
  fulfillmentCenters: IFulfillmentCentersState;
  firebase: any;
}

const rootReducer: Reducer<IAppState> = combineReducers({
  auth,
  dashboard,
  modals,
  integrations,
  campaigns,
  inventories,
  bucket,
  reports,
  oneLink,
  templates,
  users,
  reasons,
  organizations,
  shell,
  tools,
  fulfillmentCenters,
  firebase: firebaseReducer,
  automations,
});

export default rootReducer;
