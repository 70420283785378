import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';

import { ADDRESS1, ADDRESS2, CITY, COUNTRY, STATE, ZIP } from '../../../constants/shipping';
import { IOneLinkReceiverFixedAddress } from '../../../types/oneLink';
import { IFormFieldConfig, ISelectorValue } from '../../../types/shell';
import { CountrySelector } from '../../index';
import { ActionButton } from '../buttons';
import { Input } from '../inputs';

import styles from './FixedAddressForm.module.scss';

interface IProps {
  onCancel?: () => void;
  onSubmit?: () => void;
  form: FormikProps<IOneLinkReceiverFixedAddress>;
  requiredFields: Record<string, boolean>;
  className?: string;
  config?: { [key in keyof IOneLinkReceiverFixedAddress]: IFormFieldConfig };
}

const FixedAddressForm: React.FC<IProps> = ({ onCancel, onSubmit, form, requiredFields, className }) => {
  const getFieldErrors = React.useCallback(
    (fieldName: keyof IOneLinkReceiverFixedAddress) => {
      return form.errors[fieldName];
    },
    [form.errors],
  );

  const handleFormFieldChange = React.useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
      name: keyof IOneLinkReceiverFixedAddress,
    ) => {
      await form.setFieldValue(name, e.target.value);
    },
    [form.setFieldValue],
  );

  const handleSelectInputChange = React.useCallback(
    async (name: keyof IOneLinkReceiverFixedAddress, selected: ISelectorValue) => {
      const { value } = selected || {};
      await form.setFieldValue(name, value);
    },
    [form.setFieldValue],
  );

  return (
    <div role="form" className={cn(styles.shippingForm, className)}>
      <div className={styles.info}>
        <div className={styles.inputWrapper}>
          <Input
            helperText="Address 1"
            name={ADDRESS1}
            className={cn(styles.input, styles.fullWidth)}
            inputClassName={styles.inputField}
            placeholder="Address 1"
            required={requiredFields[ADDRESS1]}
            value={form.values[ADDRESS1]}
            onChange={(e) => handleFormFieldChange(e, ADDRESS1)}
            error={getFieldErrors(ADDRESS1)}
          />
          <Input
            helperText="Address 2"
            name={ADDRESS2}
            className={styles.input}
            inputClassName={styles.inputField}
            placeholder="Address 2"
            required={requiredFields[ADDRESS2]}
            value={form.values[ADDRESS2]}
            onChange={(e) => handleFormFieldChange(e, ADDRESS2)}
            error={getFieldErrors(ADDRESS2)}
          />
          <Input
            helperText="City"
            name={CITY}
            className={styles.input}
            inputClassName={styles.inputField}
            placeholder="City"
            required={requiredFields[CITY]}
            value={form.values[CITY]}
            onChange={(e) => handleFormFieldChange(e, CITY)}
            error={getFieldErrors(CITY)}
          />
          <Input
            helperText="State"
            name={STATE}
            className={styles.input}
            inputClassName={styles.inputField}
            placeholder="State"
            required={requiredFields[STATE]}
            value={form.values[STATE]}
            onChange={(e) => handleFormFieldChange(e, STATE)}
            error={getFieldErrors(STATE)}
          />
          <Input
            helperText="ZIP Code"
            name={ZIP}
            className={styles.input}
            inputClassName={styles.inputField}
            placeholder="ZIP Code"
            required={requiredFields[ZIP]}
            value={form.values[ZIP]}
            onChange={(e) => handleFormFieldChange(e, ZIP)}
            error={getFieldErrors(ZIP)}
          />
          <CountrySelector
            containerClassName={cn(styles.input, styles.fullWidth, styles.countrySelectContainer)}
            className={cn({ [styles.errorBorder]: form.errors[COUNTRY] }, styles.inputField, styles.countrySelect)}
            label="Country"
            name={COUNTRY}
            placeholder="Country"
            required={requiredFields[COUNTRY]}
            value={form.values[COUNTRY]}
            onChange={handleSelectInputChange}
            error={getFieldErrors(COUNTRY)}
          />
        </div>
        <div className={styles.controls}>
          <ActionButton className={styles.cancelButton} onClick={onCancel}>
            Cancel
          </ActionButton>
          <ActionButton
            disabled={form.isSubmitting}
            type="submit"
            className={styles.continueButton}
            title="Continue"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default FixedAddressForm;
