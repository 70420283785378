import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DropdownWithSpinner, IntegrationWarningModal, IntervalInput } from '../../components';
import { ActionButton, AddEmailForm, Checkbox } from '../../components/forms';
import InventorySidebar from '../../components/InventorySidebar/InventorySidebar';
import { AI_DID_NO_CHANGES_WARNING } from '../../constants/ai';
import { RECEIVER_REMINDER_INTERVAL } from '../../constants/bucket';
import { SOB_USER_NO_GMAIL_INTEGRATION_MESSAGE } from '../../constants/integrations';
import { EMPTY_INTEGRATION_WARNING, INTEGRATION_WARNINGS_INFO } from '../../constants/integrationWarnings';
import { routes } from '../../constants/routing';
import {
  ADD_BULK_EMAIL_VALIDATION_SCHEMA,
  ADD_EMAIL_VALIDATION_SCHEMA,
  EMAIL_MESSAGE,
  EMAIL_SUBJECT,
  EMAIL_TEMPLATE_ID,
  INITIAL_ADD_EMAIL_FORM_STATE,
  RECEIVER_EMAIL,
} from '../../constants/templates';
import useModal from '../../hooks/useModal';
import { addBucketValue, addEmail } from '../../store/actions/bucket';
import { emailCheckStart } from '../../store/actions/integrations';
import { improveTextRequest } from '../../store/actions/shell';
import { fetchEmailsTemplatesRequest } from '../../store/actions/templates';
import { selectIsSignedIn } from '../../store/selectors/auth';
import {
  selectBucketItems,
  selectEmailInformation,
  selectEmailSelectedTemplate,
  selectIsDigitalBucket,
  selectIsReceiverReminderSettingEnabled,
  selectIsSOBEnabled,
  selectIsSOBUserEmailIntegrationDisabled,
  selectPreCreatedBoxId,
  selectPYGCampaignsList,
  selectReminderInterval,
  selectTemplateValues,
} from '../../store/selectors/bucket';
import { selectCampaignById } from '../../store/selectors/campaigns';
import { selectIsEmailAuthenticated, selectIsEmailIntegrationLoading } from '../../store/selectors/integrations';
import {
  selectCurrentOrganizationEmailType,
  selectIsCurrentOrganizationSupportedAnyEmail,
} from '../../store/selectors/organizations';
import { selectReceiverEmail } from '../../store/selectors/shipping';
import { selectEmailTemplates, selectEmailTemplatesIsLoading } from '../../store/selectors/templates';
import { IFlowStepProps } from '../../types/bucket';
import {
  IChatGPTSelectorValue,
  IImproveTextResponse,
  InventorySidebarTypeEnum,
  NotificationListEnum,
} from '../../types/shell';
import { EmailTemplateTypesEnum, IAddEmailFormValues, IEmailTemplate } from '../../types/templates';
import notification from '../../utils/notification';
import { setTemplateTokens } from '../../utils/template';
import { handleApiError } from '../../utils/ui';

import styles from './AddEmailContainer.module.scss';

const AddEmailContainer: React.FC<IFlowStepProps> = ({
  next,
  isBulk,
  isOneLink,
  prev,
  SenderInfo,
  isFlowMetadataLoading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bucketItems = useSelector(selectBucketItems);
  const PYGCampaignsList = useSelector(selectPYGCampaignsList);
  const email = useSelector(selectEmailInformation);
  const selectedTemplate = useSelector(selectEmailSelectedTemplate);
  const isDigitalBucket = useSelector(selectIsDigitalBucket);
  const receiverEmail = useSelector(selectReceiverEmail);
  const emailTemplates = useSelector(selectEmailTemplates);
  const isLoadingEmails = useSelector(selectEmailTemplatesIsLoading);
  const isSignedIn = useSelector(selectIsSignedIn);
  const templateValues = useSelector(selectTemplateValues);
  const isEmailAuthenticated = useSelector(selectIsEmailAuthenticated);
  const isEmailIntegrationLoading = useSelector(selectIsEmailIntegrationLoading);
  const emailType = useSelector(selectCurrentOrganizationEmailType);
  const isEmailSupported = useSelector(selectIsCurrentOrganizationSupportedAnyEmail);
  const boxId = useSelector(selectPreCreatedBoxId);
  const isDigitalOrDSflow = useSelector(selectIsReceiverReminderSettingEnabled);
  const receiverReminderInterval = useSelector(selectReminderInterval);
  const {
    delayed_shipping_template_default: boxDefaultDelayedTemplateId,
    digital_link_template_default: boxDefaultDigitalTemplateId,
    receiver_reminder_interval: boxDefaultReminderInterval,
  } = useSelector(selectCampaignById(boxId)) || {};
  const isSOBEnabled = useSelector(selectIsSOBEnabled);
  const isSOBUserEmailIntegrationDisabled = useSelector(selectIsSOBUserEmailIntegrationDisabled);

  const [isTextImproveSubmitting, toggleTextImproveSubmitting] = React.useState(false);

  const skippedBySOBFlow = React.useMemo(
    () => isSOBEnabled && isSOBUserEmailIntegrationDisabled,
    [isSOBEnabled, isSOBUserEmailIntegrationDisabled],
  );

  const isSkipped = React.useMemo(
    () => selectedTemplate === null || skippedBySOBFlow,
    [selectedTemplate, skippedBySOBFlow],
  );

  const isReceiverReminderSettingEnabled = React.useMemo(
    () => isDigitalOrDSflow || isBulk,
    [isDigitalOrDSflow, isBulk],
  );

  const sortedMessageTemplates = React.useMemo(() => {
    return emailTemplates?.filter(
      (template) =>
        template.type ===
        (isDigitalBucket ? EmailTemplateTypesEnum.DIGITAL_GIFT : EmailTemplateTypesEnum.DELAYED_SHIPPING),
    );
  }, [emailTemplates, isDigitalBucket]);

  const { openModal, closeModal, Modal, isOpen: isModalOpened } = useModal({ required: true });

  const fetchEmailTemplates = React.useCallback(() => dispatch(fetchEmailsTemplatesRequest()), [dispatch]);

  const checkEmail = React.useCallback(() => {
    dispatch(emailCheckStart());
  }, [dispatch]);

  const persistEmail = React.useCallback(
    (emailToAdd: Partial<IAddEmailFormValues>) => {
      dispatch(addEmail(emailToAdd));
    },
    [dispatch],
  );

  const handleChange = React.useCallback(
    (name: keyof IAddEmailFormValues, value: string) => {
      persistEmail({ [name]: value });
    },
    [persistEmail],
  );

  const formatTemplate = React.useCallback(
    (id: string | undefined) => {
      if (id && sortedMessageTemplates && sortedMessageTemplates.length) {
        const { item_id, body, subject } =
          sortedMessageTemplates.find((template: IEmailTemplate): IEmailTemplate | null => {
            return id === template.item_id ? template : null;
          }) || {};

        if (item_id !== undefined && body !== undefined) {
          return {
            [EMAIL_TEMPLATE_ID]: item_id,
            [EMAIL_MESSAGE]: isBulk || isOneLink ? body : setTemplateTokens(body, templateValues), // No need to parse if in Bulk mode
            [EMAIL_SUBJECT]: subject || '',
          };
        }
      }
      return null;
    },
    [sortedMessageTemplates, templateValues, isBulk, isOneLink],
  );

  const handleSelectTemplate = React.useCallback(
    (id: string) => {
      const formattedEmail = formatTemplate(id);
      if (formattedEmail) {
        persistEmail(formattedEmail);
      }
    },
    [persistEmail, formatTemplate],
  );

  const findTemplateFunction = React.useCallback(
    (template: IEmailTemplate) => {
      const isDefaultDigital = isDigitalBucket && boxDefaultDigitalTemplateId;
      const isDefaultDelayed = !isDigitalBucket && boxDefaultDelayedTemplateId;
      const searchCriteria = isDefaultDigital ? boxDefaultDigitalTemplateId : boxDefaultDelayedTemplateId;

      return isDefaultDigital || isDefaultDelayed ? template.item_id === searchCriteria : template.is_default;
    },
    [isDigitalBucket, boxDefaultDigitalTemplateId, boxDefaultDelayedTemplateId],
  );

  const defaultTemplate = React.useMemo(() => {
    if (sortedMessageTemplates && sortedMessageTemplates.length) {
      const { item_id: defaultTemplateId } =
        sortedMessageTemplates.find(findTemplateFunction) || sortedMessageTemplates[0];
      return { ...formatTemplate(defaultTemplateId), [RECEIVER_EMAIL]: receiverEmail };
    }
  }, [findTemplateFunction, sortedMessageTemplates, formatTemplate, receiverEmail]);

  const emptyTemplate = React.useMemo(() => ({ ...INITIAL_ADD_EMAIL_FORM_STATE, [EMAIL_TEMPLATE_ID]: null }), []);

  const handleChangeReminderInterval = React.useCallback(
    (interval: string | null) => {
      dispatch(addBucketValue({ [RECEIVER_REMINDER_INTERVAL]: interval }));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (isReceiverReminderSettingEnabled && boxDefaultReminderInterval && receiverReminderInterval === '') {
      handleChangeReminderInterval(boxDefaultReminderInterval);
    }
  }, [
    handleChangeReminderInterval,
    isReceiverReminderSettingEnabled,
    boxDefaultReminderInterval,
    receiverReminderInterval,
  ]);

  React.useEffect(() => {
    if (!email?.email_template_id && !isSkipped && defaultTemplate) {
      if (defaultTemplate) {
        persistEmail(defaultTemplate);
      }
    }
  }, [defaultTemplate]);

  React.useEffect(() => {
    if (
      !isBulk &&
      !isModalOpened &&
      isEmailSupported &&
      !isSkipped &&
      !isEmailIntegrationLoading &&
      !isFlowMetadataLoading &&
      !isSOBEnabled &&
      // Checking is integration loaded and only then
      // is it authenticated
      isEmailAuthenticated !== undefined &&
      !isEmailAuthenticated
    ) {
      openModal();
    }
  }, [
    openModal,
    isSkipped,
    isEmailSupported,
    isEmailAuthenticated,
    isModalOpened,
    isEmailIntegrationLoading,
    isSOBEnabled,
    isFlowMetadataLoading,
    isBulk,
  ]);

  React.useEffect(() => {
    if (!isSOBUserEmailIntegrationDisabled && !isBulk) {
      checkEmail();
    }
  }, [isSOBUserEmailIntegrationDisabled, checkEmail, isBulk]);

  const onHandleSubmit = React.useCallback(
    (emailToAdd: IAddEmailFormValues) => {
      if (isSkipped) {
        persistEmail(emptyTemplate);
      } else {
        persistEmail(emailToAdd);
      }
      if (next) {
        history.push(next);
      }
    },
    [isSkipped, persistEmail, emptyTemplate, history, next],
  );

  const handleImproveTextSubmit = React.useCallback(
    (value: IChatGPTSelectorValue) => {
      const { instruction, fieldName, config } = value;

      return new Promise((resolve, reject) => {
        toggleTextImproveSubmitting(true);
        dispatch(
          improveTextRequest({
            text: instruction(email?.email_message || ''),
            ...config,
            resolve,
            reject,
          }),
        );
      })
        .then((res) => {
          if (!(res as IImproveTextResponse)?.text.trim()) {
            return notification.warning(NotificationListEnum.Warning, {
              content: AI_DID_NO_CHANGES_WARNING,
            });
          }
          handleChange((fieldName || EMAIL_MESSAGE) as keyof IAddEmailFormValues, (res as IImproveTextResponse).text);
          notification.success(NotificationListEnum.Success, {
            content: 'Text successfully improved!',
          });
        })
        .catch(handleApiError('Something bad happened. Text was not generated'))
        .finally(() => toggleTextImproveSubmitting(false));
    },
    [dispatch, email?.email_message, handleChange, toggleTextImproveSubmitting],
  );

  React.useEffect(() => {
    if (skippedBySOBFlow) {
      notification.warning(NotificationListEnum.SOBUserHasNoGmailIntegration, {
        content: SOB_USER_NO_GMAIL_INTEGRATION_MESSAGE,
      });
    }
  }, [skippedBySOBFlow]);

  React.useEffect(() => {
    if (isSignedIn) {
      fetchEmailTemplates();
    }
  }, [isSignedIn]);

  const toggleSkip = React.useCallback(() => {
    if (skippedBySOBFlow) {
      return notification.warning(NotificationListEnum.SOBUserHasNoGmailIntegration, {
        content: SOB_USER_NO_GMAIL_INTEGRATION_MESSAGE,
      });
    }
    if (isSkipped && defaultTemplate) {
      persistEmail(defaultTemplate);
    } else {
      persistEmail(emptyTemplate);
    }
  }, [isSkipped, skippedBySOBFlow, defaultTemplate, emptyTemplate, persistEmail]);

  const handleWarningClose = React.useCallback(() => {
    if (prev) {
      history.push(prev);
    }
    closeModal();
  }, [history, prev, closeModal]);

  const handleWarningContinueClick = React.useCallback(() => {
    if (isSkipped) {
      if (next) {
        history.push(next);
      }
    } else {
      history.push(routes.integrations);
    }
    closeModal();
  }, [history, next, isSkipped, closeModal]);

  const WarningModal = React.useMemo(() => {
    const { icon, description } = emailType ? INTEGRATION_WARNINGS_INFO[emailType] : EMPTY_INTEGRATION_WARNING;

    const action = [
      <Checkbox
        key="skip"
        className={styles.checkbox}
        text="Skip this step"
        checked={isSkipped}
        textAbout="This option allows you to skip email attaching to a send"
        onChange={toggleSkip}
      />,
      <ActionButton key="continue" title="Continue" onClick={handleWarningContinueClick} />,
    ];

    return (
      <Modal className="integration-warning">
        {() => (
          <IntegrationWarningModal onClose={handleWarningClose} icon={icon} description={description} action={action} />
        )}
      </Modal>
    );
  }, [Modal, isSkipped, toggleSkip, emailType, handleWarningClose, handleWarningContinueClick]);

  return (
    <React.Fragment>
      {WarningModal}
      <div className={styles.addEmailContainer}>
        <div className={styles.form}>
          {SenderInfo}
          <DropdownWithSpinner
            disabled={isSkipped}
            helperText="Template style"
            className={styles.dropdown}
            items={sortedMessageTemplates || []}
            selected={selectedTemplate}
            onSelect={handleSelectTemplate}
            isLoading={isLoadingEmails}
            placeholder="Select template"
          />
          <AddEmailForm
            onImproveText={handleImproveTextSubmit}
            isTextImproveSubmitting={isTextImproveSubmitting}
            onSubmit={onHandleSubmit}
            onChange={handleChange}
            initialValues={email || INITIAL_ADD_EMAIL_FORM_STATE}
            validation={
              isBulk || isOneLink
                ? ADD_BULK_EMAIL_VALIDATION_SCHEMA(isDigitalBucket)
                : ADD_EMAIL_VALIDATION_SCHEMA(isDigitalBucket)
            }
            isBulkEngagement={isBulk}
            toggleSkip={toggleSkip}
            isSkipped={isSkipped}
            isOneLink={isOneLink}
          />
        </div>
        <div className={styles.sidebarContainer}>
          {isReceiverReminderSettingEnabled && (
            <IntervalInput value={receiverReminderInterval} onChange={handleChangeReminderInterval} />
          )}
          <InventorySidebar
            className={styles.inventorySidebar}
            items={PYGCampaignsList || bucketItems}
            type={PYGCampaignsList ? InventorySidebarTypeEnum.Campaign : InventorySidebarTypeEnum.InventoryItem}
            label="Your Send"
            showQuantity
            showCount={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEmailContainer;
