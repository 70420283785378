import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { components, GroupBase, OptionProps } from 'react-select';

import { DEPT_ADMIN, USER } from '../../../../../../constants/users';
import { selectCurrentOrganizationDepartmentList } from '../../../../../../store/selectors/organizations';
import { BUTTON_BUTTON } from '../../../../../../types/forms';
import { getUserRoleLabel, hasPermission } from '../../../../../../utils/users';
import { ISelectorValueWithUser } from '../../../../../modals/CommonModals/ReassignUserModal/ReassignUserModal';

import styles from './UserOption.module.scss';

const UserOption:
  | React.ComponentType<OptionProps<ISelectorValueWithUser, boolean, GroupBase<ISelectorValueWithUser>>>
  | undefined = (props) => {
  const { className, selectOption, data } = props;

  const departments = useSelector(selectCurrentOrganizationDepartmentList);

  const userDepartmentName = React.useMemo(() => {
    const departmentName = departments?.find((d) => d.uid === data.user.department_id)?.name;

    return hasPermission([DEPT_ADMIN, USER], data.user.admin_type) ? departmentName : null;
  }, [departments, data.user]);

  const userRole = React.useMemo(() => getUserRoleLabel(data.user?.admin_type), [data.user]);

  const handleClick = React.useCallback(() => {
    selectOption(data);
  }, [data, selectOption]);

  return (
    <components.Option {...props}>
      <button onClick={handleClick} type={BUTTON_BUTTON} className={cn(styles.container, className)}>
        <span className={styles.userNames}>
          {data.user.first_name} {data.user.last_name}
        </span>
        <span className={styles.roleAndDept}>{`${userRole}${
          userDepartmentName ? `, ${userDepartmentName}` : ''
        }`}</span>
      </button>
    </components.Option>
  );
};

export default UserOption;
