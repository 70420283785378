import cn from 'classnames';
import * as React from 'react';

import { IFilterItem } from '../../types/shell';

import styles from './Filter.module.scss';

interface IProps {
  className?: string;
  config: IFilterItem[];
}

const Filter: React.FC<IProps> = ({ config, className: containerClassName }) => (
  <div className={cn(styles.filter, containerClassName)}>
    {config.map(({ className, isActive, key, label, onClick }) =>
      isActive ? (
        <span className={cn(styles.filterLabel, className, styles.chosen)} key={key}>
          {label}
        </span>
      ) : (
        <button onClick={onClick} className={cn(styles.filterLabel, className)} key={key}>
          {label}
        </button>
      ),
    )}
  </div>
);

export default Filter;
