import { BOX_ID } from '../../constants/campaigns';
import {
  IAddCampaignRequest,
  IAddCampaignResponse,
  ICampaign,
  ICampaignCandidate,
  IDeleteCampaignRequest,
  IDeleteCampaignResponse,
  IEditCampaignRequest,
  IEditCampaignResponse,
  IFetchCampaignByIdRequestPayload,
  IFetchCampaignByIdResponsePayload,
  IFetchCampaignsRequest,
  IFetchCampaignsResponse,
  IFetchCampaignSummariesRequestPayload,
  IFetchCampaignSummariesResponsePayload,
} from '../../types/campaigns';
import { IApiError } from '../../types/shell';

export const FETCH_CAMPAIGNS_REQUEST = 'FETCH_CAMPAIGNS_REQUEST';
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_FAILURE = 'FETCH_CAMPAIGNS_FAILURE';

export const FETCH_CAMPAIGN_BY_ID_REQUEST = 'FETCH_CAMPAIGN_BY_ID_REQUEST';
export const FETCH_CAMPAIGN_BY_ID_SUCCESS = 'FETCH_CAMPAIGN_BY_ID_SUCCESS';
export const FETCH_CAMPAIGN_BY_ID_FAILURE = 'FETCH_CAMPAIGN_BY_ID_FAILURE';

export const ADD_CAMPAIGN_REQUEST = 'ADD_CAMPAIGN_REQUEST';
export const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS';
export const ADD_CAMPAIGN_FAILURE = 'ADD_CAMPAIGN_FAILURE';

export const EDIT_CAMPAIGN_REQUEST = 'EDIT_CAMPAIGN_REQUEST';
export const EDIT_CAMPAIGN_SUCCESS = 'EDIT_CAMPAIGN_SUCCESS';
export const EDIT_CAMPAIGN_FAILURE = 'EDIT_CAMPAIGN_FAILURE';

export const DELETE_CAMPAIGN_REQUEST = 'DELETE_CAMPAIGN_REQUEST';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAILURE = 'DELETE_CAMPAIGN_FAILURE';

export const FETCH_CAMPAIGN_SUMMARIES_REQUEST = 'FETCH_CAMPAIGN_SUMMARIES_REQUEST';
export const FETCH_CAMPAIGN_SUMMARIES_SUCCESS = 'FETCH_CAMPAIGN_SUMMARIES_SUCCESS';
export const FETCH_CAMPAIGN_SUMMARIES_FAILURE = 'FETCH_CAMPAIGN_SUMMARIES_FAILURE';

export const ADD_CAMPAIGN_VALUE = 'ADD_CAMPAIGN_VALUE';
export const CLEAR_CAMPAIGN_DETAILS = 'CLEAR_CAMPAIGN_DETAILS';
export const CLEAR_CAMPAIGN_VALUE = 'CLEAR_CAMPAIGN_VALUE';
export const SET_INTERMEDIATE_FC = 'SET_INTERMEDIATE_FC';

export const fetchCampaignsRequest = (payload: IFetchCampaignsRequest) => ({
  type: FETCH_CAMPAIGNS_REQUEST,
  payload,
});

export const fetchCampaignsSuccess = (payload: IFetchCampaignsResponse) => ({
  type: FETCH_CAMPAIGNS_SUCCESS,
  payload,
});

export const fetchCampaignsFailure = (payload: IApiError | Error | unknown) => ({
  type: FETCH_CAMPAIGNS_FAILURE,
  payload,
});

export const fetchCampaignByIdRequest = (payload: IFetchCampaignByIdRequestPayload) => ({
  type: FETCH_CAMPAIGN_BY_ID_REQUEST,
  payload,
});

export const fetchCampaignByIdSuccess = (payload: IFetchCampaignByIdResponsePayload) => ({
  type: FETCH_CAMPAIGN_BY_ID_SUCCESS,
  payload,
});

export const fetchCampaignByIdFailure = (payload: IApiError) => ({
  type: FETCH_CAMPAIGN_BY_ID_FAILURE,
  payload,
});

export const addCampaignRequest = (payload: IAddCampaignRequest) => ({
  type: ADD_CAMPAIGN_REQUEST,
  payload,
});

export const addCampaignSuccess = (payload: IAddCampaignResponse) => ({
  type: ADD_CAMPAIGN_SUCCESS,
  payload,
});

export const addCampaignFailure = (payload?: IApiError | Error | unknown) => ({
  type: ADD_CAMPAIGN_FAILURE,
  payload,
});

export const editCampaignRequest = (payload: IEditCampaignRequest) => ({
  type: EDIT_CAMPAIGN_REQUEST,
  payload,
});

export const editCampaignSuccess = (payload: IEditCampaignResponse) => ({
  type: EDIT_CAMPAIGN_SUCCESS,
  payload,
});

export const editCampaignFailure = (payload?: IApiError | Error | unknown) => ({
  type: EDIT_CAMPAIGN_FAILURE,
  payload,
});

export const deleteCampaignRequest = (payload: IDeleteCampaignRequest) => ({
  type: DELETE_CAMPAIGN_REQUEST,
  payload,
});

export const deleteCampaignSuccess = (payload: IDeleteCampaignResponse) => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  payload,
});

export const deleteCampaignFailure = (payload?: IApiError | Error | unknown) => ({
  type: DELETE_CAMPAIGN_FAILURE,
  payload,
});

export const addCampaignValue = (payload?: Partial<ICampaignCandidate | ICampaign>) => ({
  type: ADD_CAMPAIGN_VALUE,
  payload,
});

export const clearCampaignDetails = () => ({
  type: CLEAR_CAMPAIGN_DETAILS,
});

export const clearCampaignValue = (payload: keyof Omit<ICampaignCandidate, typeof BOX_ID>) => ({
  type: CLEAR_CAMPAIGN_VALUE,
  payload,
});

export const fetchCampaignSummariesRequest = (payload: IFetchCampaignSummariesRequestPayload) => ({
  type: FETCH_CAMPAIGN_SUMMARIES_REQUEST,
  payload,
});
export const fetchCampaignSummariesSuccess = (payload: IFetchCampaignSummariesResponsePayload) => ({
  type: FETCH_CAMPAIGN_SUMMARIES_SUCCESS,
  payload,
});

export const fetchCampaignSummariesFailure = (payload: IApiError | Error | unknown) => ({
  type: FETCH_CAMPAIGN_SUMMARIES_FAILURE,
  payload,
});

export const setIntermediateFCID = (payload: string) => ({ type: SET_INTERMEDIATE_FC, payload });
