import {
  LOW_COUNT_CAMPAIGN_WARNING,
  LOW_INVENTORY_COUNT,
  UNKNOWN_COUNT_VALUE,
  UNKNOWN_COUNT_WARNING,
} from '../constants/inventories';
import { ICampaign, ICampaignFormItem, ICampaignItem } from '../types/campaigns';
import {
  IInventoryItem,
  IInventoryWithType,
  InventoryTypesEnum,
  IOnDemandItem,
  ITypedItemsPick,
} from '../types/inventories';
import { IReportEngagementItem } from '../types/reports';
import { SortOrder } from '../types/shell';
import {
  getMinInventoryItemCount,
  getSKUOptionsTotalCount,
  isItemDigital,
  isItemMsku,
  isItemOutOfStock,
} from './inventories';

export const getBoxItem = (item: IInventoryItem | ICampaignItem): ICampaignFormItem => {
  return {
    item_id: item.item_id,
    size: item.size || '',
    color: item.color || '',
    gender: item.gender,
    flavor: item.flavor || '',
    other_option: item.other_option || '',
    department_ids: [],
    description: item.description,
    fulfillment_center_id: item.fulfillment_center_id,
    org_id: item.org_id,
    type: item.type,
    name: item.name,
    price: item.price ? item.price : undefined,
    currency_code: item.currency_code,
    quantity: (item as ICampaignItem).quantity || 0,
    image_url: item.image_url,
    sku_options: item.sku_options,
    product_sku: item.product_sku || '',
    is_hidden_for_recipient: item.is_hidden_for_recipient ? item.is_hidden_for_recipient : false,
    item_customization_allowed: item.item_customization_allowed,
    preorder_allowed: item.preorder_allowed,
  };
};

interface IItemEligibleForTotalCalculation {
  price?: number;
  sku_options?: IItemEligibleForTotalCalculation[];
  quantity?: number;
}

export const sortByPriority = (order: SortOrder) => (a: ICampaign, b: ICampaign) => {
  if (order === SortOrder.ASC) {
    return a.priority - b.priority;
  }

  if (order === SortOrder.DESC) {
    return b.priority - a.priority;
  }

  return 0;
};

export const getCampaignCount = (items: ICampaignItem[]) => {
  if (!items || !items.length) {
    return UNKNOWN_COUNT_VALUE;
  }

  return Math.min(
    ...items.map(({ count, sku_options, quantity }) => {
      const itemCount = getMinInventoryItemCount({ count, sku_options });

      return itemCount >= 0 ? itemCount / quantity : UNKNOWN_COUNT_VALUE;
    }),
  );
};

export const getHasAnyPhysicalItems = (items: ICampaignItem[]) => {
  const physicalItemsArray = items?.filter((item) => item.type === InventoryTypesEnum.Physical) || [];

  return physicalItemsArray.length > 0;
};

export const getCampaignHasSomeHiddenItems = (items: (ICampaignItem | IOnDemandItem)[]) => {
  const physicalItemsArray = items?.filter((item) => item.type === InventoryTypesEnum.Physical) || [];

  return physicalItemsArray.length && physicalItemsArray.some((item) => item.is_hidden_for_recipient);
};

export const getIsItemLastVisibleInCampaign = (
  items: (ICampaignItem | IOnDemandItem)[] | undefined,
  itemId: string,
) => {
  const hiddenPhysicalItemIDs = items?.filter((item) => item.is_hidden_for_recipient).map((item) => item.item_id) || [];
  const visiblePhysicalItemIDs =
    items?.filter((item) => !item.is_hidden_for_recipient).map((item) => item.item_id) || [];

  return (
    !hiddenPhysicalItemIDs.includes(itemId) &&
    visiblePhysicalItemIDs.length === 1 &&
    visiblePhysicalItemIDs.includes(itemId)
  );
};

export const getDoesCampaignContainOnlyHiddenItems = (items: ICampaignItem[] | undefined) => {
  return items?.length && items.every((item) => item.is_hidden_for_recipient);
};

export const getIsCampaignItemLastNotHidden = (items: ICampaignItem[] | undefined, itemId: string) => {
  const hiddenPhysicalItems =
    items
      ?.filter((item) => item.type === InventoryTypesEnum.Physical && item.is_hidden_for_recipient)
      .map((item) => item.item_id) || [];
  const notHiddenPhysicalItems =
    items
      ?.filter((item) => item.type === InventoryTypesEnum.Physical && !item.is_hidden_for_recipient)
      .map((item) => item.item_id) || [];

  return !hiddenPhysicalItems.includes(itemId) && notHiddenPhysicalItems.every((item) => item === itemId);
};

export const getCanRemoveItemFromCampaign = (items: (ICampaignItem | IOnDemandItem)[] | undefined, itemId: string) => {
  const isLastNotHiddenItem = getIsItemLastVisibleInCampaign(items, itemId);
  const isDigitalCampaign = items?.length && items.some((item) => item.type === InventoryTypesEnum.Digital);

  return !isLastNotHiddenItem || isDigitalCampaign || !getCampaignHasSomeHiddenItems(items || []);
};

/**
 * Check if a digital box contains only digital items.
 *
 * @param {ITypedItemsPick|null} box - The digital box object to check.
 * @returns {boolean} - True if the box contains only digital items, false otherwise.
 */
export const isDigitalBox = (box?: ITypedItemsPick | null) => {
  const { items } = box || {};

  if (!items) {
    return false;
  }

  return items.every(isItemDigital);
};

export const hasItemsFromFC = (
  items: ICampaignItem[] | undefined | IReportEngagementItem[] | null,
  fulfillmentCenterId: string,
) => {
  if (!items) {
    return false;
  }

  return items.some((item: ICampaignItem) => item.fulfillment_center_id === fulfillmentCenterId);
};

export const hasSomeOfType = (items: IInventoryWithType[] | null | undefined, type: InventoryTypesEnum) => {
  return items?.some((item) => item.type === type);
};

export const getCampaignFCID = (items: ICampaignItem[] | IInventoryItem[]) => {
  return items?.[0]?.fulfillment_center_id;
};

export const getCampaignCountWarning = (items: ICampaignItem[]) => {
  const count = getCampaignCount(items);

  switch (true) {
    case count === UNKNOWN_COUNT_VALUE:
      return UNKNOWN_COUNT_WARNING;
    case count < LOW_INVENTORY_COUNT:
      return LOW_COUNT_CAMPAIGN_WARNING;
    default:
      return null;
  }
};

export const mapCampaignItemsForEditCampaignRequest = ({
  quantity,
  item_id,
  is_hidden_for_recipient,
}: ICampaignItem) => ({
  quantity,
  item_id,
  is_hidden_for_recipient,
});

/**
 * Determines if a campaign is eligible for ordering based on the provided items.
 * Eligibility is determined based on the product type and its availability.
 *
 * @param {ICampaignItem[] | undefined} items - The array of campaign items
 * @returns {boolean} - Returns true if the campaign is eligible for ordering, otherwise false
 */
export const isCampaignEligibleToOrder = (items: ICampaignItem[] | undefined) => {
  const resultArray = items?.map((item) => {
    switch (true) {
      case isItemMsku(item): {
        const maxMskuCountAvailable = getSKUOptionsTotalCount(item.sku_options);
        return typeof maxMskuCountAvailable !== 'undefined' ? maxMskuCountAvailable >= item.quantity : true;
      }
      case isItemDigital(item):
      case item.preorder_allowed: {
        return true;
      }
      case isItemOutOfStock(item): {
        return false;
      }
      default:
        return item.count! >= item.quantity;
    }
  });

  return resultArray?.every(Boolean);
};
