import * as React from 'react';
import { useSelector } from 'react-redux';
import { animated, config as springConfig } from 'react-spring';
import ScaleInOut from '../../animations/ScaleInOut';

import { selectUserProfile } from '../../store/selectors/auth';
import { selectCurrentOrganization } from '../../store/selectors/organizations';

import styles from './CurrentUserInfo.module.scss';

const CurrentUserInfo: React.FC = () => {
  const userProfile = useSelector(selectUserProfile);
  const currentOrganization = useSelector(selectCurrentOrganization);

  return (
    <ScaleInOut trigger={!!currentOrganization?.name} config={springConfig.gentle}>
      {({ style }) => (
        <animated.div style={style} className={styles.wrapper}>
          <div className={styles.name}>{userProfile?.displayName}</div>
          <div className={styles.organization}>{currentOrganization?.name}</div>
        </animated.div>
      )}
    </ScaleInOut>
  );
};
export default CurrentUserInfo;
