import cn from 'classnames';
import * as React from 'react';

import { DateFormatsEnum } from '../../../../constants/date';
import { HelpTooltip } from '../../../tooltips';
import { InputLabel } from '../../labels';
import { DateSinglePicker, Toggle } from '../index';

import styles from './HoldUntilDateInput.module.scss';

interface IProps {
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  onToggleChange?: (value: boolean) => void;
  readOnly?: boolean;
}

const HoldUntilDateInput = ({ className, value = '', onChange, onToggleChange, readOnly }: IProps) => {
  const [isDateShown, setIsDateShown] = React.useState(!!value);
  const [isToggleChecked, setIsToggleChecked] = React.useState(false);

  const handleSelectDate = React.useCallback(
    (val: string | null) => {
      onChange?.(val ?? '');
    },
    [onChange],
  );

  const handleResetDate = React.useCallback(() => {
    onChange?.('');
  }, [onChange]);

  const handleToggleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;
      if (!checked) {
        handleResetDate();
      }

      setTimeout(() => {
        setIsDateShown(checked);
        setIsToggleChecked(checked);
      }, 0);
    },
    [onChange, handleResetDate],
  );

  React.useEffect(() => {
    if (value && !isDateShown) {
      setIsDateShown(true);
    }
  }, [value, isDateShown]);

  React.useEffect(() => {
    if (typeof onToggleChange === 'function') {
      onToggleChange(isToggleChecked);
    }
  }, [isToggleChecked, onToggleChange]);

  return (
    <div className={cn(className, styles.container)}>
      <InputLabel
        value={
          <React.Fragment>
            Put on hold
            <HelpTooltip id="put-on-hold-tooltip" className={styles.hint}>
              <React.Fragment>
                This option lets you put the order
                <br />
                on hold until it’s ready to be sent.
              </React.Fragment>
            </HelpTooltip>
          </React.Fragment>
        }
      />
      <div className={styles.controls}>
        <Toggle checked={isDateShown} onChange={handleToggleChange} readOnly={readOnly} className={styles.toggle} />
        {isDateShown && (
          <DateSinglePicker
            minDate={new Date()}
            contentClassName={styles.dateInput}
            className={styles.datePicker}
            value={value}
            formatRule={DateFormatsEnum.yyyyMMdd}
            onSelect={handleSelectDate}
            onReset={handleResetDate}
          />
        )}
      </div>
    </div>
  );
};

export default HoldUntilDateInput;
