import cn from 'classnames';
import * as React from 'react';

import { BUTTON_BUTTON } from '../../../../types/forms';
import { UISizeEnum } from '../../../../types/shell';

import styles from './ActionButton.module.scss';

export interface IActionButtonProps {
  className?: string;
  title?: string;
  children?: React.ReactNode | React.ReactNode[];
  size?: UISizeEnum.Normal | UISizeEnum.Small;
  onClick?: (...args: any[]) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  severity?: 'main' | 'secondary';
  outlined?: boolean;
  hint?: string;
  inline?: boolean;
}

const ActionButton: React.FC<IActionButtonProps> = ({
  title,
  size = UISizeEnum.Normal,
  onClick,
  onMouseEnter,
  onMouseLeave,
  type = BUTTON_BUTTON,
  disabled,
  children,
  className,
  severity = 'main',
  outlined = false,
  hint,
  inline,
}) => {
  return (
    <button
      {...(hint ? { title: hint } : {})}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      className={cn(styles.actionButton, styles[size], styles[severity], className, {
        [styles.outlined]: outlined,
        [styles.inline]: inline,
      })}
      type={type}
    >
      {title || children}
    </button>
  );
};

export default ActionButton;
