import bucketTransforms from './bucket';
import campaignsTransforms from './campaigns';
import dashboardTransforms from './dashboard';
import fulfillmentCentersTransforms from './fulfillmentCenters';
import inventoriesTransforms from './inventories';
import modalsTransforms from './modals';
import organizationTransforms from './organizations';
import reasonsTransforms from './reasons';
import shellTransforms from './shell';
import templatesTransforms from './templates';
import toolsTransforms from './tools';
import usersTransforms from './users';

const transforms = [
  ...bucketTransforms,
  ...modalsTransforms,
  ...usersTransforms,
  ...toolsTransforms,
  ...templatesTransforms,
  ...shellTransforms,
  ...organizationTransforms,
  ...campaignsTransforms,
  ...inventoriesTransforms,
  ...reasonsTransforms,
  ...fulfillmentCentersTransforms,
  ...dashboardTransforms,
];

export default transforms;
