import cn from 'classnames';
import { FieldAttributes } from 'formik';
import * as React from 'react';
import { TooltipProps } from 'react-tooltip';

import { HelpTooltip } from '../../../index';
import { InputLabel } from '../../labels';

import styles from './Toggle.module.scss';

export interface IProps extends FieldAttributes<any> {
  sliderClassName?: string;
  infoClassName?: string;
  switchClassName?: string;
  className?: string;
  id?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  hint?: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  disabled?: boolean;
  value?: string | boolean;
  defaultValue?: string | boolean;
  checked?: boolean;
  children?: React.ReactNode;
  inverse?: boolean;
  compact?: boolean;
  tooltipProps?: TooltipProps;
}

const Toggle: React.FC<IProps> = ({
  sliderClassName,
  switchClassName,
  className,
  name,
  value,
  defaultValue,
  onChange,
  onClick,
  hint,
  helperText,
  field,
  disabled,
  checked,
  children,
  infoClassName,
  inverse = false,
  compact = false,
  tooltipProps = {},
}: IProps) => {
  const isChecked = React.useMemo(() => {
    return typeof checked !== 'undefined' ? checked : value === defaultValue || field?.value === defaultValue;
  }, [value, checked, defaultValue, field]);

  const handleChange = (e: any) => {
    if (onChange) {
      onChange(e);
    }
    if (field?.onChange) {
      field.onChange(e);
    }
  };

  return (
    <div
      className={cn(styles.toggle, className, {
        [styles.disabled]: disabled,
        [styles.inverse]: inverse,
        [styles.compact]: compact,
      })}
    >
      <InputLabel value={helperText} />
      <div className={styles.toggleInner}>
        <label className={cn(styles.switch, switchClassName)}>
          <input
            name={name || field?.name}
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
            onClick={onClick}
            disabled={disabled}
            value={value || field?.value}
          />
          <span className={cn(styles.slider, sliderClassName)} />
          {children && <div className={cn(styles.sliderInfo, infoClassName)}>{children}</div>}
        </label>
        {hint && (
          <HelpTooltip {...tooltipProps} id={`toggle-tooltip-${name || field.name}`}>
            {hint}
          </HelpTooltip>
        )}
      </div>
    </div>
  );
};

export default Toggle;
