import isEqual from 'lodash/isEqual';
import * as React from 'react';

import { ActionButton, SendItemsForm } from '../../../components';
import { IChangeBucketItemPayload } from '../../../types/bucket';
import { ICampaignItem } from '../../../types/campaigns';
import { IOnDemandItem } from '../../../types/inventories';
import { EngagementSourceTypesEnum, IReportEngagementItem, IReportWithCampaignSummaries } from '../../../types/reports';
import { BoxDetailsSidebarModeEnum, UISizeEnum } from '../../../types/shell';
import { isValidScheduledEngagement } from '../../../utils/reports';
import { AddSendItemsSidebar } from '../../index';
import { actions, reducer } from './reducer';

import styles from './EditEngagementItemsContainer.module.scss';

export interface IEditItemsRef {
  items?: IReportEngagementItem[] | null;
  getValues: () => IReportEngagementItem[];
}

interface IProps {
  onSubmit: (items?: IReportEngagementItem[] | null) => void;
  report: IReportWithCampaignSummaries | null | undefined;
}

const EditEngagementItemsContainer = React.forwardRef(({ report, onSubmit }: IProps, ref: React.Ref<IEditItemsRef>) => {
  const [isAddItemsSidebarShown, setIsAddItemsSidebarShown] = React.useState<boolean>(false);

  const [items, changeItems] = React.useReducer(reducer(report?.items), report?.items || []);

  const handleItemChange = React.useCallback((payload: IChangeBucketItemPayload) => {
    changeItems(actions.change(payload));
  }, []);

  const handleRemove = React.useCallback(({ item_id }: ICampaignItem | IOnDemandItem) => {
    changeItems(actions.remove(item_id));
  }, []);

  const handleAddItemsSubmit = React.useCallback((itemCandidates?: IReportEngagementItem[]) => {
    changeItems(actions.replace(itemCandidates || []));
    setIsAddItemsSidebarShown(false);
  }, []);

  const actionButtons = React.useMemo(
    () => [
      <ActionButton
        className={styles.continueButton}
        key="submit-button"
        onClick={() => onSubmit(items)}
        disabled={isEqual(items, report?.items)}
        title="Save changes"
      />,
    ],
    [items, report?.items, onSubmit],
  );

  const isEditingSendItemsAllowed = React.useMemo(() => {
    return report && !isValidScheduledEngagement(report);
  }, [report]);

  const isDirectSendCampaign = React.useMemo(() => {
    return report?.engagement_source_type === EngagementSourceTypesEnum.DirectSend;
  }, [report]);

  const isDirectSend = React.useMemo(() => {
    return report?.engagement_source_type === EngagementSourceTypesEnum.DirectSend;
  }, [report]);

  React.useImperativeHandle(
    ref,
    () => ({
      getValues: () => items,
    }),
    [items],
  );

  // need to add default quantity to items added to the send, otherwise the price for these items won't be applied

  const mappedItems = React.useMemo(
    () =>
      items.map((item) => ({
        ...item,
        quantity: item.quantity ?? 1,
      })),
    [items],
  );

  return (
    <React.Fragment>
      <div className={styles.headingContainer}>
        <div className={styles.title}>Edit Items</div>
        {isEditingSendItemsAllowed && (
          <ActionButton
            className={styles.headerBtn}
            size={UISizeEnum.Small}
            title="Edit items"
            outlined
            onClick={() => setIsAddItemsSidebarShown(true)}
          />
        )}
      </div>
      <SendItemsForm
        mode={BoxDetailsSidebarModeEnum.Edit}
        onChange={handleItemChange}
        onRemove={handleRemove}
        items={mappedItems}
        canRemoveAll={false}
        isDirectSend={isDirectSendCampaign}
      >
        {actionButtons}
      </SendItemsForm>
      {isEditingSendItemsAllowed && (
        <AddSendItemsSidebar
          onClose={() => setIsAddItemsSidebarShown(false)}
          isShown={isAddItemsSidebarShown}
          onSubmit={handleAddItemsSubmit}
          reportItems={items}
          status={report?.ship_order_status}
          isDirectSend={isDirectSend}
        />
      )}
    </React.Fragment>
  );
});

export default EditEngagementItemsContainer;
